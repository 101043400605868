import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"
import logo from "../svg/logo.svg"

export default function MissingData(props) {
  const navigate = useNavigate()
  const handleRedirect = () => navigate("/app/manage-data", {state: {pageSource: props.pageSource} })
  
  var showUpload = true
  var showAdditionalText = false
  if (props.showUpload == false) showUpload = false
  if (props.additionalText) showAdditionalText = true
  
  return (
    <div className="missing-data flex-center">
      <div>
        <h1>{props.text}</h1>
        <div className="justify-content-center">
          {showUpload && <Button onClick={handleRedirect}>Upload</Button>}
          {showAdditionalText && <h5>{props.additionalText}</h5>}
        </div>
        <img alt="Vertical City Logo" src={logo} height={500} width={500} />
      </div>
    </div>           
  )
}