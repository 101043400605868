// icon svgs
import hydro from './torontohydro.png'
import water from './torontowater.png'
import enwave from './enwave.png'
import defaultSteam from '../VCI Logo and Icons-steam.png'
import defaultNaturalGas from '../VCI Logo and Icons-naturalgas.png'
import defaultElectricity from '../VCI Logo and Icons-electricity.png'
import defaultWater from '../VCI Logo and Icons-water.png'

// icons and custom dimensions
const mapIconDimensions = {
  "hydro": {
    "img": hydro, 
    "width":'130px', 
    "height": '50px'
  },
  "water": {
    "img": water, 
    "width":'131px', 
    "height": '50px'
  },
  "toronto": {
    "img": water, 
    "width":'131px', 
    "height": '50px'
  },
  "enwave": {
    "img": enwave, 
    "width":'130px', 
    "height": '40px',
    "marginBottom": '10px'
  },
  "default_steam": {
    "img": defaultSteam,
    "width":'60px', 
    "height": '50px',
  },
  "default_naturalgas": {
    "img": defaultNaturalGas,
    "width":'60px', 
    "height": '50px',
  },
  "default_electricity": {
    "img": defaultElectricity,
    "width":'60px', 
    "height": '50px',
  },
  "default_water": {
    "img": defaultWater,
    "width":'60px', 
    "height": '50px',
  }
}

export default function getUtilityIcon(company,type) {
  let lowerCompany = company.toLowerCase()
  let lowerCompanySplit = lowerCompany.split(' ')
  let size = lowerCompanySplit.length
  var utilitiesIcon = null
  
  // TODO improve search method once we have more utility providers
  // reverse iterate becase "toronto hydro" will match with 'toronto' and 'hydro' key - we want hydro to take priority for this case
  for (let i in lowerCompanySplit)
  {
    for (let key in mapIconDimensions)
    {
      if (lowerCompanySplit[size-1-i] == key) utilitiesIcon = mapIconDimensions[key]
    }
  }

  if(utilitiesIcon === null)
  {
    utilitiesIcon = mapIconDimensions['default_' + type]
  }

  return utilitiesIcon
}
