import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_IP,
  withCredentials: true
})

api.interceptors.request.use(
  (config) => config,
  (error) => {
    console.error("API request error occurred:", error)
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API error occurred:", error)
    
    // Server responded with a status code that falls outside the range of 2xx
    if (error.response) 
    {
      switch (error.response.status) {
        case 401:
          return Promise.reject(error)
        case 404:
          break
        case 500:
          return
        case 409: 
          return
        // Add more cases as needed
        default:
          break
      }
    }
    
    // The request was made, but no response was received
    else if (error.request)
    {
      console.error("No response received:", error.request)
    }
    
    // Something else happened while setting up the request
    else
    {
      console.error("Error setting up the request:", error.message)
    }

    // Reject the promise, but make sure to handle it in your components
    return Promise.reject(error)
  }
)

// Function to handle streaming data using fetch
export const fetchStreamingData = async (endpoint, body, onMessageChunk) => {
  const url = `${process.env.REACT_APP_BACKEND_IP}${endpoint}`
  
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include'
    })

    const reader = response.body.getReader()
    let buffer = ''

    function processChunk(chunk) {
      buffer += chunk
      while (true) 
      {
        let startIndex = buffer.indexOf('{')
        let endIndex = buffer.indexOf('}')
        if (startIndex !== -1 && endIndex !== -1) 
        {
          const jsonChunk = buffer.substring(startIndex, endIndex + 1)
          const data = JSON.parse(jsonChunk)
          onMessageChunk(data) // Call callback with parsed chunk
          buffer = buffer.substring(endIndex + 1)
        } 
        else break
      }
    }

    async function readChunk() {
      const { done, value } = await reader.read()
      if (done) return
      processChunk(new TextDecoder().decode(value))
      await readChunk()
    }

    await readChunk()
  } catch (error) {
    console.error('Error fetching streaming data:', error)
    throw error
  }
}

export default api
