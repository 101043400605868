import { Button } from "react-bootstrap"
import logo from "../svg/logo.svg"
import { useNavigate } from "react-router-dom"

export default function PageNotFound() {
  const navigate = useNavigate()

   return (
    <div className="PageNotFound">
      <h1>404</h1>
      <h3>We're sorry but we can't seem to find the page you're looking for.</h3>
      <img alt="Vertical City Logo" src={logo} />
      <Button onClick={() => navigate("/")}>
        Return to site
      </Button>
    </div>
   )
 }
