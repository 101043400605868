import { useEffect, useState, useRef, useContext, useLayoutEffect } from 'react'
import { todaysDate } from '../../lib/helpers/formattingHelpers.js'
import { fetchStreamingData } from '../../lib/api.js'

// Context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// Components
import MissingData from './MissingData.js'
import { OverlayTrigger, Popover, PopoverBody, Modal, Button, Form } from 'react-bootstrap'

// logo svgs
import logo from '../../svg/logo.svg'
import profile from '../../svg/icon-profile.png'

// button svgs
import deleteIcon from '../../svg/icon-delete.svg'
import editIcon from '../../svg/icon-edit.svg'
import whiteRefreshIcon from "../../svg/refresh-white.svg"
import greyRefreshIcon from "../../svg/refresh-grey.svg"
import documentIcon from "../../svg/icon-document.svg"

export default function Chat() {
  // context and navigation
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------

  const [showMissingData, setShowMissingData] = useState(false)
  const [dataExists, setDataExists] = useState(false)
  const [mediumScreen, setMediumScreen] = useState(false)
  const [mobileScreen, setMobileScreen] = useState(false)
  const [renderFirstPrompt, setRenderFirstPrompt] = useState(false)

  // for sending and receiving chats
  const [firstPromptSent, setFirstPromptSent] = useState(true)
  const [messages, setMessages] = useState([])
  const [currentMessage, setCurrentMessage] = useState({})
  const [streaming, setStreaming] = useState(false)
  const [lastId, setLastId] = useState(0)
  const [currentChatHistory, setCurrentChatHistory] = useState([])
  const [newMessages, setNewMessages] = useState([])
  const [lastQuestion, setLastQuestion] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  // for document reference
  const [showDocumentRefModal, setShowDocumentRefModal] = useState(false)

  // for chat history
  const [chatId, setChatId] = useState(-1)
  const [chatHistoryId, setChatHistoryId] = useState(-1)
  const [selectedChatIndex, setSelectedChatIndex] = useState(-1)
  const [showSidebar, setShowSidebar] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [historyToModify, setHistoryToModify] = useState({id: -1, text: ""})
  const [scrollPosition,setScrollPosition] = useState(0)
  
  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  function AddMessage(user, message, messageId, history)
  {
    setLastId(prevId => {
      const newId = prevId + 1
      const newItem = { id: newId, userMsg: user, message: message, messageId: messageId }
      setMessages(prevArray => [...prevArray, newItem])
      if (history)
      {
        setCurrentChatHistory(prevArray => [...prevArray, newItem])
      }
      else
      {
        setNewMessages(prevArray => [...prevArray, newItem])
      }
      return newId // Update lastId
    })
  }

  function UpdateHistory(message)
  {
    if (dataExists)
    {
      data.setChatHistories(prevArray => {
        // Clone the outer array
        const outerArray = [...prevArray]
        if (selectedChatIndex !== -1)
        {
          const innerArray = [...outerArray[selectedChatIndex].messages]
          innerArray.push(message)
          outerArray[selectedChatIndex].messages = innerArray
        }

        return outerArray
      })  
    } 
  }

  function GetMessageContext(messageId)
  {
    setShowDocumentRefModal(false)
    data.fetchMessageContext(messageId)
  }

  function FindIndexBychatId(array, value)
  {
    for (let i = 0; i < array.length; i++) {
      if (array[i].messages[0] === parseInt(value)) return i // Return the index of the array
    }
    return -1 // Return -1 if the value is not found
  }

  function GetChatResponse( message, setMessage)
  {
    if (message === "") return
    if (message.endsWith("\\")) message += "\\";

    // set the last message 
    setLastQuestion(message)

    // clear out the current message
    setCurrentMessage({
      chunkNum: 0,
      message: "",
      lastChunk: 0,
      chatHistoryId: chatId
    })

    // Send Request for chat response
    if (chatHistoryId === 0) setChatHistoryId(-1)
    const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "chat_history_id": chatHistoryId == 0 ? -1: chatHistoryId, "date": todaysDate(),"message":  message }

    fetchStreamingData('/stream/chat', body, (data) => {
      setCurrentMessage(prevMessage => ({
        chunkNum: data.chunk_number,
        message: data.message_chunk !== "[Done]" ? prevMessage.message + data.message_chunk : data.message_chunk,
        lastChunk: data.last_chunk,
        chatHistoryId: data.chat_history_id
      }))
    })

    if (setMessage !== undefined) setMessage("")
  }

  //------------------------------------------------------------------------------------------------------------------
  // Handle Function
  //------------------------------------------------------------------------------------------------------------------

  // function called when new chat button is clicked
  const handleNewChat = () => {
    setChatId(-1)
    setChatHistoryId(-1)
    setMessages([])
    setFirstPromptSent(false)
    data.fetchChatHistory()
  }

  const handleInnerClick = (e) => {
    e.stopPropagation()
  }

  const handleRegenerateResponse = () => {
    GetChatResponse(lastQuestion)
    setShowErrorMessage(false)
  }

  // updates chart properties based on window size
  const resizeMobile = () => {
    if (window.matchMedia("(max-width: 560px)").matches)
    {
      setMobileScreen(true)
      setMediumScreen(true)
    }
    else if (window.matchMedia("(max-width: 980px)").matches)
    {
      setMobileScreen(false)
      setShowSidebar(false)
      setMediumScreen(true)
    }
    else if (!window.matchMedia("(max-width: 980px)").matches )
    {
      setMobileScreen(false)
      setShowSidebar(true)
      setMediumScreen(false)
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // fire once on page load
  useEffect(() => {
    if (auth.isContextSecured)
    {
      data.fetchNumBuildingDocs()
    }
  }, [auth.isContextSecured])

  useEffect(() => {
    if (data.numBuildingDocs === 0)
    {
      setShowMissingData(true)
      setDataExists(false)
    }
    else
    {
      setShowMissingData(false)
      setDataExists(true)
    }

    if (mobileScreen) setShowSidebar(false)

    // get the chat history
    data.fetchChatHistory()
    setChatId(auth.chatId)
    setChatHistoryId(auth.chatId)
  }, [data.numBuildingDocs])

  // update messages
  useEffect(() => {
    if (currentMessage.message !== undefined) setStreaming(true)
    
    if (currentMessage.message !== "[Done]" && currentMessage.lastChunk !== 1 && currentMessage.chunkNum !== -1)
    {
      // prevMessages will always have the most recent state
      setMessages(prevMessages => {
        return prevMessages.map(obj => {
          if (obj.id === lastId) 
          {
            return { ...obj, message: currentMessage.message }
          }
          return obj
        })
      })

      // prevMessages will always have the most recent state
      setNewMessages(prevMessages => {
          return prevMessages.map(obj => {
            if (obj.id === lastId) 
            {
              return { ...obj, message: currentMessage.message }
            }
            return obj
          })
      })
    } 
    else if (currentMessage.lastChunk === 1)
    {
      setMessages(prevMessages => {
        // prevMessages will always have the most recent state
          return prevMessages.map(obj => {
            if (obj.id === lastId) 
            {
              return { ...obj, messageId: currentMessage.chunkNum }
            }
            return obj
          })
        })
        setNewMessages(prevMessages => {
          // prevMessages will always have the most recent state
            return prevMessages.map(obj => {
              if (obj.id === lastId) 
              {
                return { ...obj, messageId: currentMessage.chunkNum }
              }
              return obj
            })
        })
    }

    else if (currentMessage.chunkNum === -1) setShowErrorMessage(true)

    if (currentMessage.lastChunk)
    {
      UpdateHistory(messages[messages.length - 1].message)
      setStreaming(false)
      setChatHistoryId(currentMessage.chatHistoryId)
    }

  }, [currentMessage.message])

  // called when messages are added
  useEffect(() => {
    setFirstPromptSent(messages.length !== 0)
  }, [messages.length !== 0])

  useEffect(() => {

    const timeout = setTimeout(() => {
      setRenderFirstPrompt(true) // Set your state after the delay
    }, 200) // Delay of 200 milliseconds

    // Clear the timeout if the component unmounts or the delay isn't needed anymore
    return () => clearTimeout(timeout)

  }, [firstPromptSent])

  // update chat histories
  useEffect(() => {
    var question = false
    var answer = false
    var messageId = false
    var answerText = ""
    setMessages([])
    setNewMessages([])
    setCurrentChatHistory([])

    if (dataExists) auth.updatechatId(chatId)

    if (data.numHistories !== 0) setShowSidebar(true)
    else setShowSidebar(false)

    const chatIndex = FindIndexBychatId(data.chatHistories,chatId)
    setSelectedChatIndex(chatIndex)

    if (chatId !== -1 && chatIndex !== -1 && data.chatHistories[chatIndex])
    {
      data.chatHistories[chatIndex].messages.map((element) => {
        if(!question && !answer && !messageId)
        {
          question = true
        }
        else if(question) 
        {
          AddMessage(true,element,0,true)
          question = false
          answer = true
          messageId = false
        } 
        else if (answer) 
        {
          answerText = element
          question = false
          answer = false
          messageId = true
        } 
        else 
        {
          AddMessage(false,answerText,element,true)
          question = false
          answer = false
          messageId = false
          answerText = ""
        }
      })
    }
    setLastQuestion("")
    data.fetchChatHistory()

  },[chatId, data.numHistories])

  useEffect(() => {
    if (data.selectedSections.length !== 0) setShowDocumentRefModal(true)
  }, [data.selectedSections])

  // Run every time this component is rerendered
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile)

    // Clean up function
    return () => {
      window.removeEventListener('resize', resizeMobile)
    }

  }, [])

  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  function Messages(){
    const messagesContainerRef = useRef(null)

    useLayoutEffect(() => {
      scrollToBottom()
    })

    const scrollToBottom = () => {
      if (messagesContainerRef.current) messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }

    function Message(props) {
      const name = props.userMsg ? auth.firstName : auth.selectedBuilding

      return (
        <div className='message'>
          <div className='flex' style={{ gridGap: '5px', paddingLeft: '10px' }}>
            {!props.userMsg && <img src={logo} alt="VCI Logo" width="35" height="35" />}
            {props.userMsg && <img src={profile} alt="User Img" width="30" height="30" style={{ marginLeft: '2px', marginRight: '3px' }} />}
            <h5>{name}</h5>
          </div>
          <div style={{ paddingLeft: '50px', paddingRight: '30px', whiteSpace: 'wrap' }}>
            <pre>
              {props.message}
              {!props.userMsg && streaming && (props.id === lastId) && (
                <div className='legend-size circle-legend' />
              )}
            </pre>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: '50px', marginTop: '5px' }}>
            {lastQuestion !== "" && !streaming && !props.userMsg && (props.id === lastId) && (
              <OverlayTrigger placement="bottom" overlay={<Popover><PopoverBody style={{ marginBottom: '2px', padding: '5px' }}>Regenerate</PopoverBody></Popover>}>
                <button className='button-hidden-bordered' style={{padding: 0}} onClick={handleRegenerateResponse}>
                  <img src={greyRefreshIcon} style={{margin: '1px', marginTop: '0px'}} alt="Refresh Button" width="24" height="24" />
                </button>
              </OverlayTrigger>
            )}
            {!props.userMsg && !(props.messageId === -1) && ((props.id !== lastId) || ((props.id === lastId) && !streaming)) && (
              <button className='button-hidden-bordered' style={{ width: '105px', display: 'flex', alignItems: 'center' }} onClick={() => { GetMessageContext(props.messageId) }}>
                <img style={{ marginBottom: '2px', marginRight: '2px' }} src={documentIcon} alt="Document Button" width="15" height="15" />
                <p style={{ margin: 0 }}>Document</p>
              </button>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className='messages' ref={messagesContainerRef}>
        {firstPromptSent && currentChatHistory.map((obj) => (
          <Message userMsg={obj.userMsg} message={obj.message} id={obj.id} messageId={obj.messageId}/>
        ))}  

        {firstPromptSent && newMessages.map((obj) => (
          <Message userMsg={obj.userMsg} message={obj.message} id={obj.id} messageId={obj.messageId}/>
        ))}
        {showErrorMessage && <RegenerateButton />}
      </div>
    )
  }

  function PrevChatButtons() {
    const prevChatButtonContainerRef = useRef(null)
    const desiredGroupOrder = ["Today", "Yesterday", "Previous 7 Days", "Older"]

    const handleClick = (id) => {
      setScrollPosition(prevChatButtonContainerRef.current.scrollTop)
      setChatId(id) 
      setChatHistoryId(id)
    }

    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split('-')
      const date = new Date(year, month - 1, day)
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(today.getDate() - 1)
    
      if (date.toDateString() === today.toDateString()) return "Today"
      else if (date.toDateString() === yesterday.toDateString()) return "Yesterday"
      else if (date >= new Date(today.setDate(today.getDate() - 7))) return "Previous 7 Days"
      else return "Older"
    }

    // sort the chatHistories by date
    const groupedChatHistories = data.chatHistories
      .slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date)) 
      .reduce((acc, element) => {
        const group = formatDate(element.date)
        if (!acc[group]) acc[group] = []
        acc[group].push(element)
        return acc
      }, {})

    // After the action, scroll back to the previous position
    useLayoutEffect(() => {
      if (prevChatButtonContainerRef.current) prevChatButtonContainerRef.current.scrollTo({top: scrollPosition})
    },[scrollPosition])

    // adds the selected class to whichever button was selected
    useEffect(() => {
      // add and removie selected class from previous chat buttons
      const element1 = document.getElementById(chatId)
      const element2 = document.getElementsByClassName('selected')
      
      if (element1 && !streaming) 
      {
        if (element2[0]) element2[0].classList.remove('selected')
        element1.classList.add('selected')
      }
    },[])
    
    return (
      <>
        {(data.numHistories && Object.keys(groupedChatHistories).length !== 0) ? (
          <>
            <div ref={prevChatButtonContainerRef} className="prev-chat-button-container">
              {Object.keys(groupedChatHistories).sort((a, b) => desiredGroupOrder.indexOf(a) - desiredGroupOrder.indexOf(b)).map((group) => (
                <div key={group}>
                  <p id="heading">{group}</p> 
                  {groupedChatHistories[group].slice().reverse().map((element) => (
                    <div className='chat-history-container' key={element.messages[0]}>
                      <button
                        id={element.messages[0]}
                        className={`chat-history-button ${parseInt(element.messages[0]) === chatId ? "selected" : ""}`}
                        style={{ height: '40px' }}
                        onClick={() => handleClick(element.messages[0])}
                      >
                        {(element.chatname === "" && element?.messages?.[1]?.length !== undefined) ? (
                          <>
                            {element.messages[1].length < 20 ? (
                              <>
                                <p id="button">{element.messages[1].substr(0, 20)}</p>
                                <div className='options-container' onClick={handleInnerClick}>
                                  <OptionsMenu containerRef={prevChatButtonContainerRef} id={element.messages[0]} text={element.messages[1].substr(0, 20)} />
                                </div>
                              </>
                            ) : (
                              <>
                                <p id="button">{element.messages[1].substr(0, 20)}...</p>
                                <div className='options-container' onClick={handleInnerClick}>
                                  <OptionsMenu containerRef={prevChatButtonContainerRef} id={element.messages[0]} text={`${element.messages[1].substr(0, 20)}...`} />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <p id="button">{element.chatname}</p>
                            <div className='options-container' onClick={handleInnerClick}>
                              <OptionsMenu containerRef={prevChatButtonContainerRef} id={element.messages[0]} text={element.chatname} />
                            </div>
                          </>
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ) : (<></>)}
      </>
    )
  }

  function RegenerateButton() {
    setStreaming(false)
    return (
      <div className="flex-center" style={{flexDirection:'column', marginBottom:'20px'}}>
        <hr style={{width:'100%', marginTop:'0px', marginBottom:'20px'}}></hr>
        <h5 style={{fontSize:'16px'}}>There was an error generating a response</h5>
        <Button style={{width:'125px', padding:'2px 0px', fontSize:'15px', fontWeight:'100'}} onClick={handleRegenerateResponse}>
          <img style={{'marginBottom':'3px', 'marginRight':'3px', color:'white'}} src={whiteRefreshIcon} alt="Refresh Icon" width="25" height="25" />
          Regenerate
        </Button>
      </div>
    )
  }

  function OptionsMenu(props) {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)
  
    const handleToggleMenu = () => {
      setIsOpen(!isOpen)
    }
  
    // Close menu when clicking outside
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) 
      {
        setIsOpen(false)
      }
    }

    const handleRenameButtonClick = (id) => {
      setShowRenameModal(true)
      setHistoryToModify({id:id})
    }

    const handleDeleteButtonClick = (id, text) => {
      setScrollPosition(props.containerRef.current.scrollTop)
      setShowDeleteModal(true) 
      setHistoryToModify({id:id, text:text})
    }
  
    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }, [])
  
    return (
      <div className="options-menu" ref={menuRef}>
        <button onClick={handleToggleMenu} className="menu-button">
          ••• 
        </button>
        {isOpen && (
          <div className="menu-options">
            <button className="menu-item" onClick={() => handleRenameButtonClick(props.id)}>
              <img style={{'marginBottom':'5px', 'marginRight':'5px'}} src={editIcon} alt="Edit Icon" width="16" height="16" />
              Rename
            </button>
            <button className="menu-item menu-item-danger" onClick={() => handleDeleteButtonClick(props.id,props.text)}>
              <img style={{'marginBottom':'5px', 'marginRight':'5px'}} src={deleteIcon} alt="Trash Can" width="16" height="16" />
              Delete
            </button>
          </div>
        )}
      </div>
    )
  }

  function RenameModal(props) {

    const [inputValue, setInputValue] = useState('')
    const maxLength = 20

    function handleRename(name) {
      data.renameChatHistory(name, historyToModify.id)
      data.fetchChatHistory()
      setShowRenameModal(false)
    }

    const handleInputChange = (e) => {
      if (e.target.value.length <= maxLength) 
      {
        setInputValue(e.target.value)
      }
    }

    return (
      <Modal className="rename-modal" dialogClassName="modal-width" show={props.show} onHide={() => setShowRenameModal(false)} size="lg"  centered>
        <Modal.Body>
          <Form>
            <Form.Group controlId="renameInput">
              <Form.Label>New Name</Form.Label>
              <Form.Control
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter new name"
                maxLength={maxLength} 
              />
              <Form.Text muted>
                {inputValue.length}/{maxLength} characters
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRenameModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleRename(inputValue)} disabled={inputValue.trim() === ''}>
            Rename
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function DeleteModal(props) {

    function handleDelete() {
      // delete chat history
      data.deleteChatHistory(historyToModify.id)
      setShowDeleteModal(false)
      data.fetchChatHistory()
      if (historyToModify.id === chatId) handleNewChat()
    }

    return (
      <Modal className="delete-modal" dialogClassName="modal-width" show={props.show} onHide={() => setShowDeleteModal(false)} size="lg"  centered>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will delete: <b>{historyToModify.text}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" style={{'borderColor':'#ced4da'}} onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" className="confirm-delete-button" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function DocumentRefModal(props) {
    const filteredSections = data.selectedSections.filter(
      (section) => section.document_name !== "buildingcontext"
    )
  
    return (
      <Modal className="document-ref-modal" dialogClassName="modal-width" show={props.show} onHide={() => setShowDocumentRefModal(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Documents Referenced</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredSections.length > 0 ? (
            filteredSections.map((section, index) => (
              <div key={index} className="document-section">
                <h4>{section.document_name}</h4>
                {section.document_text.split("\n\n") 
                  .map((paragraph, i) => (
                    <div key={i}>
                      <pre>{paragraph}</pre>
                      {i < section.document_text.split("\n\n").length - 2 && <hr />}
                    </div>
                  ))}
                {index < filteredSections.length - 1 && <hr className="document-separator" />}
              </div>
            ))
          ) : (
            <p>Referenced Documents are unavailable.</p>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  function Prompt() {
    const [message, setMessage] = useState("")
    const textareaRef = useRef(null)

    // handles the submit and process the data as it gets it
    function handleSubmit(event) {
      if (event) event.preventDefault()
    
      if (message === "") return

      // Add the user prompt
      UpdateHistory(message)
      AddMessage(true,message,"",false)

      //Add the chatGPT response
      AddMessage(false,"","",false)

      if (chatId === -1) auth.updatechatId(data.maxChatId+1)
    
      GetChatResponse(message, setMessage)
    }

    function handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey)
      {
        event.preventDefault()
        handleSubmit(event)
        textareaRef.current.value = ""
      }
    }

    function ExamplePrompts()
    {
      const question1 = "Which building documents are linked here?"
      const question2 = "What can I leave in my parking space?"
      const question3 = "Can I have pets in my unit?"
      const question4 = "What amenities are available in this building?"

      useEffect(() => {
        if(message === question1 || message === question2 || message === question3 || message === question4 )
        {
          handleSubmit()
        }
      }, [message])

      return (
        <div className="example-prompts-container">
          <Button variant="light" onClick={() => setMessage(question1)}>{question1}</Button>
          <Button variant="light" onClick={() => setMessage(question2)}>{question2}</Button>
          {!mobileScreen && 
          <>
            <Button variant="light" onClick={() => setMessage(question3)}>{question3}</Button>
            <Button variant="light" onClick={() => setMessage(question4)}>{question4}</Button>
          </>}
        </div>
      )
    }
    
    // textarea growing behaviour
    useEffect(() => {
        const textarea = textareaRef.current
        textarea.style.height = '20px'
        textarea.style.height = `${textarea.scrollHeight}px` // Set to scroll height for new text
      }, [message])

    // focus on prompt area after getting a response
    useEffect(() => {
      const textarea = textareaRef.current
      textarea.focus()
    }, [streaming])
  
    return (
      <>
      {!firstPromptSent && renderFirstPrompt && <ExamplePrompts />}
      <div className='prompt'>
        <textarea 
          ref={textareaRef}
          placeholder={streaming ? "":("Message " + auth.selectedBuilding + "...")}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={streaming}
        />
        <span>{auth.selectedBuilding} can make mistakes. Consider checking important information.</span>
      </div>
      </>
    )
  }

  return (
    <div>
      {showMissingData && <MissingData text="There are no Building Documents linked to your account." showUpload={false} showAdditionalText={true} additionalText="Please contact the administrator to upload building documents."/>}
      {(showDeleteModal) && <DeleteModal show={showDeleteModal}/>}
      {(showRenameModal) && <RenameModal show={showRenameModal}/>}
      {(showDocumentRefModal) && <DocumentRefModal show={showDocumentRefModal}/>}
      {!showMissingData && dataExists &&
        <div className='Chat' style={(showSidebar && !mediumScreen) ? {'marginLeft':'250px'}:{'marginLeft':'0px'}}>
          <div className="sidebar">
            {!mediumScreen ? 
            <OverlayTrigger placement="right" overlay={<Popover><PopoverBody>{showSidebar ? "Close Side Bar":"Open Side Bar"}</PopoverBody></Popover>}>
              <button 
                className={`arrow-button ${showSidebar ? '':'closed'}`} 
                onClick={() => setShowSidebar((currentState) => !currentState)}
              >
                <span className="arrow" id={showSidebar ? "left":"right"}></span>
              </button>
            </OverlayTrigger> : 
            <button 
              className={`arrow-button ${showSidebar ? '':'closed'}`} 
              onClick={() => setShowSidebar((currentState) => !currentState)}
            >
              <span className="arrow" id={showSidebar ? "left":"right"}></span>
            </button>}
            <div className={`sidebar-container ${showSidebar ? 'open' : ''}`}>
              <button className="chat-history-button" style={{'marginTop':'75px'}} onClick={handleNewChat}>
                <img src={logo} alt="VCI Logo" width="35" height="35" />
                <p id="button">New Chat</p>
                <span style={{ marginRight: '5px', fontSize: '25px', fontWeight: 'bold' }}>+</span>
              </button>
              <PrevChatButtons/>
            </div>
          </div>
          <div className='content'>
            {(!firstPromptSent && renderFirstPrompt) ? 
            <div className='first-prompt'>
              <img src={logo} alt="VCI Logo" width="80" height="80" />
              <h2>How can {auth.selectedBuilding} help you today?</h2>
            </div> : 
            <Messages />}
          </div>
          <Prompt />
        </div>
      }
    </div>
  )
}
