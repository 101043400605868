// link to this in account dropdown, under setting/manage data
import { useState, useEffect, useContext } from 'react'
import Select from "react-select"
import api from '../../lib/api.js'

// auth
import { authContext } from '../authContext.js'

// svg's
import plus from '../../svg/plus.svg'
import edit from '../../svg/edit.svg'
import link from '../../svg/link.svg'

// bootstrap
import { Button, Form, Col, Row, Container, Alert } from 'react-bootstrap'

export default function Admin() {
   const auth = useContext(authContext)

   const [formState, setFormState] = useState("")
   const [selectedUser, setSelectedUser] = useState(null)
   const [selectedBuilding, setSelectedBuilding] = useState(null)
   const [selectedLinkEmail, setSelectedLinkEmail] = useState("")
   const [selectedLinkBuilding, setSelectedLinkBuilding] = useState("")
   const [selectedLinkRole, setSelectedLinkRole] = useState("")
   //const [selectedSubscriptionVersion, setSelectedSubscriptionVersion] = useState(0)
   const [optionsUser, setOptionsUser] = useState([])
   const [optionsBuilding, setOptionsBuilding] = useState([])
   const [optionsLinks, setOptionsLinks] = useState([])
   const [optionsLinkedBuildings, setOptionsLinkedBuildings] = useState([])
   const [userTicker, setUserTicker] = useState(0)
   const [buildingTicker, setBuildingTicker] = useState(0)
   const [linkTicker, setLinkTicker] = useState(0)

   // form submission alerts
   const [showAlert, setShowAlert] = useState(false)
   const [formSuccess, setFormSuccess] = useState(false)
   const [attemptedEmail, setAttemptedEmail] = useState("")
   const [attemptedShortname, setAttemptedShortname] = useState("")


   // fixed options for roles
   const optionsRoles = [
      { value: { "role": "Property Manager" }, label: "Property Manager" },
      { value: { "role": "Board Member" }, label: "Board Member" },
      { value: { "role": "Owner" }, label: "Owner" },
      { value: { "role": "Demo0" }, label: "Demo0" },
      { value: { "role": "Demo1" }, label: "Demo1" },
      { value: { "role": "Demo2" }, label: "Demo2" }
   ]

   //------------------------------------------------------------------------------------------------------------------
   // Component Hooks
   //------------------------------------------------------------------------------------------------------------------

   useEffect(() => {
      api.get('/get/users')
      .then(function (resp) {
         const dataFromServer = resp.data
         setOptionsUser(dataFromServer)
      })
   }, [userTicker])

   useEffect(() => {
      api.get('/get/buildings')
      .then(function (resp) {
         const dataFromServer = resp.data
         setOptionsBuilding(dataFromServer)
      })
   }, [buildingTicker])

   useEffect(() => {
      api.get('/get/links')
      .then(function (resp) {
         const dataFromServer = resp.data
         setOptionsLinks(dataFromServer)
      })
   }, [linkTicker])

   
   function handleSelectForm(state) {
      setFormState(state)
      setShowAlert(false)
   }

   // TODO warn user if link already exists
   function handleSubmitNewLink(e) {
      e.preventDefault()

      const body = { "email": selectedLinkEmail, "building_short_name": selectedLinkBuilding, "role": selectedLinkRole }
      setAttemptedEmail(selectedLinkEmail)
      setAttemptedShortname(selectedLinkBuilding)

      api.post('/create/link', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log("/create/link response:", dataFromServer)
         if (dataFromServer["error"])
         {
            setShowAlert(true)
            setFormSuccess(false)
         }
         else
         {
            setShowAlert(true)
            setFormSuccess(true)

            // update current list of links
            setLinkTicker(linkTicker + 1)
         }
      })
   }

   function handleSubmitModifyLink(e) {
      e.preventDefault()

      const body = { "email": selectedLinkEmail, "building_short_name": selectedLinkBuilding, "role": selectedLinkRole }
      setAttemptedEmail(selectedLinkEmail)
      setAttemptedShortname(selectedLinkBuilding)

      api.post('/modify/link', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log("/modify/link response:", dataFromServer)
         if (dataFromServer["error"])
         {
            setShowAlert(true)
            setFormSuccess(false)
         }
         else
         {
            setShowAlert(true)
            setFormSuccess(true)

            // update current list of links
            setLinkTicker(linkTicker + 1)
         }
      })
   }

   function handleSubmitNewUser(e) {
      e.preventDefault()

      var first_name = e.target[0].value
      var last_name = e.target[1].value
      var email = e.target[2].value
      setAttemptedEmail(email)

      const body = { "first_name": first_name, "last_name": last_name, "email": email, "is_admin": auth.isAdmin }
      api.post('/create/user', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log("/create/user response:", dataFromServer)
         if (dataFromServer["already_exists"])
         {
            setShowAlert(true)
            setFormSuccess(false)
         }
         else
         {
            setShowAlert(true)
            setFormSuccess(true)

            // update current list of users
            setUserTicker(userTicker + 1)

            // send welcome email
            const welcome_body = { "email": email }
            api.post('/welcome', welcome_body)
         }
      })
   }

   function handleSubmitNewBuilding(e) {
      e.preventDefault()

      var short_name = e.target[0].value
      var building_assigned_name = e.target[1].value
      var building_address = e.target[2].value
      var building_city = e.target[3].value
      var building_province = e.target[4].value
      var building_postal = e.target[5].value
      var building_country = e.target[6].value
      var fiscal_start_date = e.target[7].value
      var fiscal_end_date = e.target[8].value
      var registration_date = e.target[9].value
      setAttemptedShortname(short_name)

      const body = {
         "short_name": short_name, "building_assigned_name": building_assigned_name, "building_address": building_address,
         "building_city": building_city, "building_province": building_province, "building_postal": building_postal, "building_country": building_country,
         "fiscal_start_date": fiscal_start_date, "fiscal_end_date": fiscal_end_date, "registration_date": registration_date
      }

      api.post('/create/building', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log("/create/building response:", dataFromServer)
         if (dataFromServer["error"])
         {
            setShowAlert(true)
            setFormSuccess(false)
         }
         else
         {
            setShowAlert(true)
            setFormSuccess(true)

            // update current list of buildings
            setBuildingTicker(buildingTicker + 1)
         }
      })
   }

   function handleSubmitModifyUser(e) {
      e.preventDefault()

      var first_name = e.target[0].value
      var last_name = e.target[1].value
      var email = e.target[2].value

      const body = { "first_name": first_name, "last_name": last_name, "email": email }
      api.post('/modify/user', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log(dataFromServer) // TODO do something with response?
      }).catch(function (error) {
         console.log(error) // TODO catch error?
      })

      // update current list of users
      setUserTicker(userTicker + 1)
   }

   function handleSubmitModifyBuilding(e) {
      e.preventDefault()

      var short_name = e.target[0].value
      var building_assigned_name = e.target[1].value
      var building_address = e.target[2].value
      var building_city = e.target[3].value
      var building_province = e.target[4].value
      var building_postal = e.target[5].value
      var building_country = e.target[6].value
      var fiscal_start_date = e.target[7].value
      var fiscal_end_date = e.target[8].value
      var registration_date = e.target[9].value

      const body = {
         "short_name": short_name, "building_assigned_name": building_assigned_name, "building_address": building_address,
         "building_city": building_city, "building_province": building_province, "building_postal": building_postal, "building_country": building_country,
         "fiscal_start_date": fiscal_start_date, "fiscal_end_date": fiscal_end_date, "registration_date": registration_date
      }

      api.post('/modify/building', body)
      .then(function (resp) {
         const dataFromServer = resp.data
         console.log(dataFromServer) // TODO do something with response?
      }).catch(function (error) {
         console.log(error) // TODO catch error?
      })

      // update current list of buildings
      setBuildingTicker(buildingTicker + 1)
   }

   function handleSelectLinkEmail(e) {
      if (optionsLinks[e.value["email"]] === undefined) return

      // find linked buildings relative to selected email
      var temp_array = []
      optionsBuilding.forEach(element => {
         // console.log(optionsLinks[e.value["email"]])

         optionsLinks[e.value["email"]].forEach(element2 => {
            if (element.label === element2) temp_array.push(element)
         })
      })
      console.log(temp_array)
      setOptionsLinkedBuildings(temp_array)

      // set selected email
      setSelectedLinkEmail(e.value["email"])
   }

   // TODO pick user from list, search by email
   // TODO show selected user or building in Modify pages
   return (
      <div className="Admin">
         <div className="content">
            <Container className="admin-dashboard-buttons">
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("new-user")}>
                  <img src={plus} alt="Plus SVG" width="30" height="30" />
                  Create User
               </Button>{' '}
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("new-building")}>
                  <img src={plus} alt="Plus SVG" width="30" height="30" />
                  Create Building
               </Button>{' '}
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("modify-user")}>
                  <img src={edit} alt="Edit SVG" width="30" height="30" />
                  Modify User
               </Button>{' '}
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("modify-building")}>
                  <img src={edit} alt="Edit SVG" width="30" height="30" />
                  Modify Building
               </Button>{' '}
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("new-link")}>
                  <img src={link} alt="Link SVG" width="30" height="30" />
                  Create User Building Link
               </Button>{' '}
               <Button variant="outline-dark" className="mx-auto my-2" onClick={() => handleSelectForm("modify-link")}>
                  <img src={link} alt="Link SVG" width="30" height="30" />
                  Modify User Building Link
               </Button>{' '}
            </Container>
            {(formState === "new-user") && (
               <Form onSubmit={handleSubmitNewUser}>
                  <h2>Create User Form</h2>
                  <Row className="mb-3">
                     <Form.Group className="mb-3" controlId="formUserFirstName" as={Col}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control placeholder="John" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formUserLastName" as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control placeholder="Smith" />
                     </Form.Group>
                  </Row>
                  <Form.Group className="mb-3" controlId="formUserEmail">
                     <Form.Label>Email</Form.Label>
                     <Form.Control placeholder="john.smith@gmail.com" />
                  </Form.Group>
                  {formSuccess && showAlert && (<Alert variant='success'>Create User for <b>{attemptedEmail}</b> successful</Alert>)}
                  {!formSuccess && showAlert && (<Alert variant='danger'>Create User for <b>{attemptedEmail}</b> unsuccessful</Alert>)}
                  <Button variant="primary" className="mx-auto my-2" type="submit">Submit</Button>{' '}
               </Form>
            )}
            {(formState === "new-building") && (
               <Form onSubmit={handleSubmitNewBuilding}>
                  <h2>Create Building Form</h2>
                  <Row className="mb-3">
                     <Form.Group className="mb-3" controlId="formBuildingShortName" as={Col}>
                        <Form.Label>Short Name</Form.Label>
                        <Form.Control placeholder="TSCC 2155" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingAssignedName" as={Col}>
                        <Form.Label>Assigned Name</Form.Label>
                        <Form.Control placeholder="TORONTO STANDARD CONDOMINIUM CORPORATION NO. 2155" />
                     </Form.Group>
                  </Row>
                  <Row className="mb-3">
                     <Form.Group className="mb-3" controlId="formBuildingAddress" as={Col}>
                        <Form.Label>Building Address</Form.Label>
                        <Form.Control placeholder="80 John Street" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingCity" as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control placeholder="TORONTO" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingProvince" as={Col}>
                        <Form.Label>Province</Form.Label>
                        <Form.Control placeholder="Ontario" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingPostal" as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control placeholder="M5V 3X4" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingCountry" as={Col}>
                        <Form.Label>Country</Form.Label>
                        <Form.Control placeholder="Canada" />
                     </Form.Group>
                  </Row>
                  <Row className="mb-3">
                     <Form.Group className="mb-3" controlId="formBuildingFiscalStart" as={Col}>
                        <Form.Label>Fiscal Start Date</Form.Label>
                        <Form.Control placeholder="MMDD" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingFiscalEnd" as={Col}>
                        <Form.Label>Fiscal End Date</Form.Label>
                        <Form.Control placeholder="MMDD" />
                     </Form.Group>
                     <Form.Group className="mb-3" controlId="formBuildingRegistrationDate" as={Col}>
                        <Form.Label>Registratioin Date</Form.Label>
                        <Form.Control placeholder="YYYYMMDD" />
                     </Form.Group>
                  </Row>
                  {formSuccess && showAlert && (<Alert variant='success'>Create Building for <b>{attemptedShortname}</b> successful</Alert>)}
                  {!formSuccess && showAlert && (<Alert variant='danger'>Create Building for <b>{attemptedShortname}</b> unsuccessful</Alert>)}
                  <Button variant="primary" className="mx-auto my-2" type="submit">Submit</Button>{' '}
               </Form>
            )}
            {(formState === "modify-user") && (
               <div id="modify-user">
                  <div id="modify-user-header">
                     <h2>Modify User Form</h2>
                     <Select className="basic-single" classNamePrefix="select" name="users" options={optionsUser} onChange={(e) => setSelectedUser(e.value)} />
                  </div>
                  {(selectedUser != null) && (
                     <Form onSubmit={handleSubmitModifyUser}>
                        <Row className="mb-3">
                           <Form.Group className="mb-3" controlId="formUserFirstName" as={Col}>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control placeholder={selectedUser['first_name']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formUserLastName" as={Col}>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control placeholder={selectedUser['last_name']} />
                           </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="formUserEmail">
                           <Form.Label>Email</Form.Label>
                           <Form.Control placeholder={selectedUser['email']} />
                        </Form.Group>
                        <Button variant="primary" className="mx-auto my-2" type="submit">Submit</Button>{' '}
                     </Form>
                  )}
               </div>
            )}
            {(formState === "modify-building") && (
               <div id="modify-building">
                  <div id="modify-building-header">
                     <h2>Modify Building Form</h2>
                     <Select className="basic-single" classNamePrefix="select" name="buildings" options={optionsBuilding} onChange={(e) => setSelectedBuilding(e.value)} />
                  </div>
                  {(selectedBuilding != null) && (
                     <Form onSubmit={handleSubmitModifyBuilding}>
                        <Row className="mb-3">
                           <Form.Group className="mb-3" controlId="formBuildingShortName" as={Col}>
                              <Form.Label>Short Name</Form.Label>
                              <Form.Control placeholder={selectedBuilding['short_name']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingAssignedName" as={Col}>
                              <Form.Label>Assigned Name</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_assigned_name']} />
                           </Form.Group>
                        </Row>
                        <Row className="mb-3">
                           <Form.Group className="mb-3" controlId="formBuildingAddress" as={Col}>
                              <Form.Label>Building Address</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_address']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingCity" as={Col}>
                              <Form.Label>City</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_city']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingProvince" as={Col}>
                              <Form.Label>Province</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_province']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingPostal" as={Col}>
                              <Form.Label>Postal Code</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_postal']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingCountry" as={Col}>
                              <Form.Label>Country</Form.Label>
                              <Form.Control placeholder={selectedBuilding['building_country']} />
                           </Form.Group>
                        </Row>
                        <Row className="mb-3">
                           <Form.Group className="mb-3" controlId="formBuildingFiscalStart" as={Col}>
                              <Form.Label>Fiscal Start Date</Form.Label>
                              <Form.Control placeholder={selectedBuilding['fiscal_start_date']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingFiscalEnd" as={Col}>
                              <Form.Label>Fiscal End Date</Form.Label>
                              <Form.Control placeholder={selectedBuilding['fiscal_end_date']} />
                           </Form.Group>
                           <Form.Group className="mb-3" controlId="formBuildingRegistrationDate" as={Col}>
                              <Form.Label>Registration Date</Form.Label>
                              <Form.Control placeholder={selectedBuilding['registration_date']} />
                           </Form.Group>
                        </Row>
                        <Button variant="primary" className="mx-auto my-2" type="submit">Submit</Button>{' '}
                     </Form>
                  )}
               </div>
            )}
            {(formState === "new-link") && (
               <div id="new-link">
                  <Form onSubmit={handleSubmitNewLink}>
                     <h2>Create User Building Link Form</h2>
                     Select User
                     <Select className="basic-single" classNamePrefix="select" name="users" options={optionsUser} onChange={(e) => setSelectedLinkEmail(e.value["email"])} />
                     Select Building
                     <Select className="basic-single" classNamePrefix="select" name="buildings" options={optionsBuilding} onChange={(e) => setSelectedLinkBuilding(e.value["short_name"])} />
                     Select Role
                     <Select className="basic-single" classNamePrefix="select" name="roles" options={optionsRoles} onChange={(e) => setSelectedLinkRole(e.value["role"])} />
                     {formSuccess && showAlert && (<Alert variant='success'>Create Link for <b>{attemptedEmail}</b> and <b>{attemptedShortname}</b> successful</Alert>)}
                     {!formSuccess && showAlert && (<Alert variant='danger'>Create Link for <b>{attemptedEmail}</b> and <b>{attemptedShortname}</b> unsuccessful</Alert>)}
                     <Button variant="primary" className="mx-auto my-2" type="submit">Submit</Button>{' '}
                  </Form>
               </div>
            )}
            {(formState === "modify-link") && (
               <div id="modify-link">
                  <Form onSubmit={handleSubmitModifyLink}>
                     <h2>Modify User Building Link Form</h2>
                     Select User
                     <Select className="basic-single" classNamePrefix="select" name="users" options={optionsUser} onChange={handleSelectLinkEmail} />
                     {(selectedLinkEmail !== "") && (
                        <div>
                           Select Building Link
                           <Select className="basic-single" classNamePrefix="select" name="buildings" options={optionsLinkedBuildings} onChange={(e) => setSelectedLinkBuilding(e.value["short_name"])} />
                        </div>
                     )}
                     {(selectedLinkEmail !== "") && (selectedLinkBuilding !== "") && (
                        <div>
                           Select Role
                           <Select className="basic-single" classNamePrefix="select" name="roles" options={optionsRoles} onChange={(e) => setSelectedLinkRole(e.value["role"])} />
                        </div>
                     )}
                     {formSuccess && showAlert && (<Alert variant='success'>Modify Link for <b>{attemptedEmail}</b> and <b>{attemptedShortname}</b> successful</Alert>)}
                     {!formSuccess && showAlert && (<Alert variant='danger'>Modify Link for <b>{attemptedEmail}</b> and <b>{attemptedShortname}</b> unsuccessful</Alert>)}
                     <Button variant="primary" className="mx-auto my-2" type="submit" disabled={(selectedLinkEmail === "") || (selectedLinkBuilding === "")}>Submit</Button>{' '}
                  </Form>
               </div>
            )}
         </div>
      </div>
   )
}
