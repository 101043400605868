import { Form, Button, Alert } from "react-bootstrap"
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import api from '../lib/api.js'

import check from '../svg/checkmark.svg'
import x from '../svg/x-red.svg'

const alertType = {
  MATCH: 'MATCH',
  LONGENOUGH: 'LONGENOUGH',
}

export default function ResetPassword() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [passwordLongEnough, setPasswordLongEnough] = useState(false)

  const [expiredToken, setExpiredToken] = useState(false)

  // set flag to indicate if passwords are the same
  function handleSubmit(event) {
    event.preventDefault()

    const form_data = { "email": state?.email, "password": password, "token": state?.token }
    api.post('/resetpasswordconfirm', form_data)
    .then(function (resp) {
      if (!resp || resp.data["error"]) 
      {
        setExpiredToken(true)
        return
      }
      navigate("/login", {state: {newPassword: true} })
    })
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  // Function to check if the passwords match
  useEffect(() => {
    setPasswordsMatch(password === confirmPassword)
    setPasswordLongEnough(password.length > 7)
  }, [password, confirmPassword])

  function PasswordAlert({bool, type}) {
    var text
    if (type == alertType.MATCH) text = "Passwords must match."
    else if (type == alertType.LONGENOUGH) text = "Password must be at least 8 characters."

    return (
      <div className="flex" style={{gap: '10px', alignItems: 'center'}}>
        <img src={bool ? check : x} alt={bool ? 'check' : 'red x'} height='15px' width='15px' />
        {text}
      </div>
    )
  }

  return (
    <div className="ResetPassword">
      <Form onSubmit={handleSubmit}>
        <h2>Reset Password</h2>

        <Form.Group size="lg" controlId="password" style={{marginTop: '20px'}}>
          <Form.Control
            type="password"
            placeholder="New Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="confirm-password">
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Form.Group>
        
        {expiredToken && (<Alert variant='danger' style={{marginTop:"10px"}}>Invalid password reset token. Your token may have expired</Alert>)}

        {password && confirmPassword && (
          <div style={{marginTop: '10px'}}>
            <PasswordAlert bool={passwordsMatch} type={alertType.MATCH} />
            <PasswordAlert bool={passwordLongEnough} type={alertType.LONGENOUGH} />
          </div>
        )}

        <Button block size="lg" type="submit" disabled={!passwordsMatch || !passwordLongEnough}>Submit</Button>
      </Form>
    </div>
  )
}