import { useState, useContext, useEffect } from "react"
import { Form, Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import api from '../lib/api.js'

// auth
import { authContext } from './authContext.js'

import check from '../svg/checkmark.svg'
import x from '../svg/x.svg'

const alertType = {
  MATCH: 'MATCH',
  LONGENOUGH: 'LONGENOUGH',
}

export default function Signup() {
  const navigate = useNavigate()
  const auth = useContext(authContext)

  const [formState, setFormState] = useState("name-form")

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [passwordLongEnough, setPasswordLongEnough] = useState(false)

  // not sending request until both forms have collected their respective requried inputs
  function handleSignup() {
    // construct signup request
    let body = {first_name: firstName, last_name: lastName, email: email, password: password}
    api.post('/create/user', body)
      .then(function (resp) {

        // TODO if user already exists, navigate user to login
        if (resp.data["user_exists_flask"])
        {
          navigate("/login", {state: {userExists: true} })
          return
        }

        // if account creation successful, login user
        body = {email: email, password: password, remember: false}
        auth.login(body).then(() => {
          navigate("/app", {state: {signup: true} })
        })
      })
  }

  function handleSubmitName(event) {
    event.preventDefault()

    const temp_firstName = event.target[0].value
    const temp_lastName = event.target[1].value
    const temp_email = event.target[2].value

    // prevent api request if no email or password are given
    if (temp_firstName.length === 0 || temp_lastName.length === 0 || temp_email.length === 0) return null

    setFirstName(temp_firstName)
    setLastName(temp_lastName)
    setEmail(temp_email)

    setFormState("email-form")
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  // Function to check if the passwords match
  useEffect(() => {
    setPasswordsMatch(password === confirmPassword)
    setPasswordLongEnough(password.length > 7)
  }, [password, confirmPassword])

  function PasswordAlert({bool, type}) {
    var text
    if (type == alertType.MATCH) text = "Passwords must match."
    else if (type == alertType.LONGENOUGH) text = "Password must be at least 8 characters."

    return (
      <div className="flex" style={{gap: '10px', alignItems: 'center'}}>
        <img src={bool ? check : x} alt={bool ? 'check' : 'red x'} height='15px' width='15px' />
        {text}
      </div>
    )
  }

  return (
    <div className="Login">
      {formState === "name-form" &&
      <Form onSubmit={handleSubmitName}>
        <h2 style={{marginBottom: '20px'}}>Sign up</h2>
        
        <div className="flex" style={{gap: '10px'}}>
          <Form.Group size="lg" controlId="firstname">
            <Form.Control placeholder="First name"/>
          </Form.Group>
          <Form.Group size="lg" controlId="lastname">
            <Form.Control placeholder="Last name" />
          </Form.Group>
        </div>

        <Form.Group size="lg" controlId="email">
          <Form.Control
            type="email"
            placeholder="Email"
          />
        </Form.Group>

        <Button block size="lg" type="submit">Next</Button>
        <p style={{margin: 0}}>Already have an account? <Link id="login" to="/login">Log in</Link></p>
      </Form>}

      {formState === "email-form" && 
      <Form onSubmit={handleSignup}>
        <h2>Create Password</h2>

        <Form.Group size="lg" controlId="password" style={{marginTop: '20px'}}>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="confirm-password">
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Form.Group>

        {password && confirmPassword && (
          <div style={{marginTop: '10px'}}>
            <PasswordAlert bool={passwordsMatch} type={alertType.MATCH} />
            <PasswordAlert bool={passwordLongEnough} type={alertType.LONGENOUGH} />
          </div>
        )}

        <Button block size="lg" type="submit" disabled={!passwordsMatch || !passwordLongEnough}>Sign Up</Button>
      </Form>}
    </div>
  )
}
