
// for report card components
export function getWeight(ratio) {
    var ratio = ratio.replace(' ', '').split('/')
    var weight = (ratio[0] / ratio[1]) * 100
    return weight
  }

// converts YYYYMMDD to date object
export function YYYYMMDDtoDate(date) {
    var year = date.toString().substring(0, 4);
    var month = date.toString().substring(4, 6) - 1;
    var day = date.toString().substring(6, 8);
    var dateObj = new Date(year, month, day)
    return dateObj
  }
  
// adds commas, $, and given decimal places
export function formatAmount(amount, numDecimals = 2, dollarPrefixEnabled = true, removeTrailingZeroes = false) {
    if (amount === undefined) return
    if (typeof amount == "string") amount = parseFloat(amount)

    let dollarPrefix = ""

    if (dollarPrefixEnabled)
    {
        if (amount < 0)
        {
            dollarPrefix = "-$"
            amount = -amount
        }
        else
        {
            dollarPrefix = "$"
        }
    }

    if (removeTrailingZeroes)
    {
        return dollarPrefix + parseFloat(amount.toFixed(numDecimals)).toLocaleString()
    }

    return dollarPrefix + amount.toLocaleString('en-US', { minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals })
}

// converts date object to the format: short month, date year (i.e. Jan 20, 2012)
export function formatDate(date, withDayNum=true) {
    const newDate = new Date()
    newDate.setTime(date)
    if (!withDayNum) return newDate.toLocaleString('default', { month: 'short' }) + ' ' + newDate.getUTCFullYear()
    return newDate.toLocaleString('default', { month: 'short' }) + ' ' + newDate.getUTCDate() + ', ' + newDate.getUTCFullYear()
}

// convert 2012-01-20 to January 20, 2012
export function formatDashesToString(date, monthStr='long') {
    if (date == null) return
    const newDate = new Date(date).toLocaleString('default', { timeZone: 'UTC', month: monthStr}) + ' ' + date.substring(8,10) + ', ' + date.substring(0,4)
    return newDate
}

export function formatDateMonth(date){
    const newDate = new Date()
    newDate.setTime(date)
    const formattedDate = newDate.toLocaleString('default', { month: 'long' })
    return formattedDate
}

// convert date format from number to string with dashes i.e. 20220101 to 2022-01-01
export function formatDateDashes(date) {
    let newDate = date.toString();
    return newDate.slice(0, 4) + "-" + newDate.slice(4, 6) + "-" + newDate.slice(6);
}

// determines the difference in days between two given JS date objects
export function helperDateDifference(date1, date2) {
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
 }

// format dollar figure with M or K i.e. $100K (thousands) or $10M (millions)
export function formatAmountKM(amount, dollarPrefixEnabled = true) {
    if (typeof amount == "string") amount = parseFloat(amount)

    let dollarPrefix = "$"

    if (!dollarPrefixEnabled)
    {
        dollarPrefix = ""
    }

    if (amount < 0)
    {
        if (dollarPrefixEnabled)
        {
            dollarPrefix = "-$"
        }
        else
        {
            dollarPrefix = "-"
        }
        amount = Math.abs(amount)
    }  

    if (amount === 0) return dollarPrefix + amount

    if (amount < 1)
    {
        return dollarPrefix + amount.toFixed(2)
    }
    else if (amount < 10000) 
    {
        return dollarPrefix + Math.round(amount)
    }
    else if (amount < 1000000)
    {
        return dollarPrefix + Math.round(amount / 1000).toLocaleString() + 'K'
    }
    else if (amount < 1000000000)
    {
        return dollarPrefix + (amount / 1000000).toPrecision(2).toLocaleString() + 'M'
    }
    else
    {
        return dollarPrefix + (amount / 1000000000).toPrecision(3).toLocaleString() + 'B'
    }
}

export function capitalize(string) {
    const words = string.split(" ")
    for (let i in words)
    {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }
    return words.join(" ")
}

export function getPercent(portion, total, round = false) {
    if (!round) return (portion / total * 100).toFixed(2)
    else return (portion / total * 100).toFixed(0)
}

// not quite a formatting helper, could go in it's own file potentially
export function isFormEmpty(formData, requiredFields = []) {
    if (!formData)
    {
        console.log("Form is empty!")
        return true
    }

    requiredFields.map(field => {
        if (!formData[field])
        {
            console.log(field + " is required!")
            return true
        }
    })

    return false
}

// returns todays date as YYYY-mm-dd
export function todaysDate() {
    var today = new Date()
    var month = today.getMonth() + 1
    var day = today.getDate()
    if (month < 10)
    {
        month = '0' + month
    }
    if (day < 10)
    {
        day = '0' + day
    }
    return today.getFullYear() + '-' + month + '-' + day
}
