import { useMemo } from "react"
import { useTable } from 'react-table'
import { Table } from 'react-bootstrap'
import { formatDate, formatAmount, YYYYMMDDtoDate } from './helpers/formattingHelpers.js';

// // converts date object to the format: short month, date year (i.e. Jan 20, 2012)
// function formatDate(date) {
//   const newDate = new Date()
//   newDate.setTime(date)
//   const formattedDate = newDate.toLocaleString('default', { month: 'short' }) + ' ' + newDate.getUTCDate() + ', ' + newDate.getUTCFullYear()
//   return formattedDate
// }

// adds commas, $, and given decimal places
// function formatAmount(amount, numDecimals = 2, dollarPrefixEnabled = true, removeTrailingZeroes = false) {
//   if (amount === undefined) return
//   if (typeof amount == "string") amount = parseFloat(amount)

//   let dollarPrefix = ""

//   if (dollarPrefixEnabled)
//   {
//     if (amount < 0)
//     {
//       dollarPrefix = "$"
//       amount = -amount
//     }
//     else dollarPrefix = "$"
//   }
//   if (removeTrailingZeroes) return dollarPrefix + parseFloat(amount.toFixed(numDecimals)).toLocaleString()
//   return dollarPrefix + amount.toLocaleString('en-US', { minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals })
// }

// optionally pass date and amount headers to format as date or number
export function formatReactableColumns(formattedRow, formatDateHeaders = [], formatAmountHeaders = []) {
  const TableColumns = Object.keys(formattedRow).map((key) => {
    if (formatDateHeaders.includes(key)) 
    {
      return {
        Header: key,
        accessor: key,
        sortType: (a, b, key) => {
          const aValue = new Date(a.values[key])
          const bValue = new Date(b.values[key])
          if (aValue > bValue) return 1;
          if (aValue < bValue) return -1;
        }
      }
    }
    else if (formatAmountHeaders.includes(key)) 
    {
      return {
        Header: key,
        accessor: key,
        sortType: (a, b, key) => {
          const aValue = parseFloat(a.values[key].slice(1).replace(',', ''))
          const bValue = parseFloat(b.values[key].slice(1).replace(',', ''))
          if (aValue > bValue) return 1;
          if (aValue < bValue) return -1;
        }
      }
    }
    else 
    {
      return {
        Header: key,
        accessor: key
      }
    }
  })

  return TableColumns
}

// this converts the data received from the server into a usable form (Array of JS objects)
export function formatServerData(dataFromServer, desiredHeaders, formatDateHeaders = [], formatAmountHeaders = [], formatRateHeaders = []) {
  let formattedData = []
  for (let i = 0; i < dataFromServer[Object.keys(dataFromServer)[0]].length; i++)
  {
    let row = {};
    // ensures proper ordering of server data - order desired headers is given in determines order
    Object.keys(desiredHeaders).map((header) => {
      row[desiredHeaders[header]] = null
    })

    for (const header in dataFromServer)
    {
      if (header in desiredHeaders)
      {
        const desiredHeader = desiredHeaders[header]
        if (formatDateHeaders.includes(desiredHeader))
        {
          row[desiredHeader] = formatDate(YYYYMMDDtoDate(dataFromServer[header][i]))
        }
        else if (formatAmountHeaders.includes(desiredHeader))
        {
          row[desiredHeader] = formatAmount(dataFromServer[header][i], 0)
        }
        else if (formatRateHeaders.includes(desiredHeader))
        {
          row[desiredHeader] = formatAmount(dataFromServer[header][i] * 100, 3, false)
        }
        else
        {
          row[desiredHeader] = dataFromServer[header][i]
        }
      }
    }
    formattedData[i] = row;
  }

  //console.log(formattedData)
  return formattedData;
}

export function ReactTableBasic(props) {
  const data = useMemo(() => props.data, [])
  const columns = useMemo(() => props.columns, [])
  const formatDateHeaders = props.formatDateHeaders ? props.formatDateHeaders : []
  const formatAmountHeaders = props.formatAmountHeaders ? props.formatAmountHeaders : []
  
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({columns, data})

  return (
    <Table hover className='react-table'>
      <thead className='stick-header-style'>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((header) => (
          <th className='header-hover'>
            <div {...header.getHeaderProps({ title: undefined })}>
              {header.render('Header')}
            </div>
          </th>
        ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map(row => {
      prepareRow(row)
      return (
        <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
          if (formatAmountHeaders.includes(cell.column.Header))
          {
            return <td {...cell.getCellProps()}>{formatAmount(cell.value, 0)}</td>
          }
          else if (formatDateHeaders.includes(cell.column.Header))
          {
            return <td {...cell.getCellProps()}>{formatDate(YYYYMMDDtoDate(cell.value))}</td>
          }
          else
          {
            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
          }
        })}
        </tr>
      )
      })}
      </tbody>
    </Table>
  )
}
