import { useEffect, useContext, useState, useRef } from "react"
import { Button } from "react-bootstrap"

// context
import { authContext } from './authContext.js'

const STEPS = {
  0: {
    status: "Ignored",
    content: ""
  },
  // first half of tour: steps 1-20 (before configuration saved)
  1: {
    status: "Landing",
    content: "This is the landing page. You can choose from our range of products (or pages) below."
  },
  2: {
    status: "SelectBuilding",
    content: "This is where you can see which building is currently associated with your account. If there are multiple buildings, you can switch the currently selected one here too."
  },
  3: {
    status: "ManageData",
    content: "This is where you upload data for the app. At minimum, the app requires a Cash Flow Table from a Reserve Fund Study to be uploaded in order to function."
  },
  4: {
    status: "ReportCardButton",
    content: "It looks like you already have uploaded a Cashflow Table so you can access the pages. This is the Report Card Page, we suggest you start here! Click the button to enter the page.",
    disableRight: true,
  },
  // 5: {
  //   status: "SelectStudyModal",
  //   content: "Before entering the Report Card Page, you must first select a Reserve Fund Study to use.",
  //   disableLeft: true,
  //   disableRight: true,
  // },
  5: {
    status: "ReportCardIntro",
    content: "This is the Report Card Page. Here is a description of what this page is used for.",
    disableLeft: true,
  },
  6: {
    status: "ReportCardRFS",
    content: "This is where you can change which Reserve Fund Study is currently selected.",
  },
  7: {
    status: "ReportCardReport",
    content: "This is the primary component of this page that gives an overview of the current health of your reserve fund. You can click the component to see more details.",
  },
  8: {
    status: "ReportCardForecast",
    content: "This chart shows the comparison of the forecast of the cashflow table within the selected reserve fund and the forecast that we generate. To understand this chart better you can click the 'Understand this' button below.",
  },
  9: {
    status: "ReportCardDetails",
    content: "This section shows extra details pertaining to both the reserve fund study forecast and Vertical City's forecast.",
  },
  10: {
    status: "ReportCardHelp",
    content: "Last but not least, this help button, if clicked, will give more insight into how this page is derived from the original cashflow table. We suggest you click this button and see what happens.",
  },
  11: {
    status: "LandingButton",
    content: "Now that you've had a tour of the Report Card page, you can click on the Vertical City logo here to return to the landing page!",
    disableRight: true,
  },
  12: {
    status: "DashboardButton",
    content: "As you can see, the Dashboard page is currently disabeld. This is because you will need to save a calculator input configuration in the Forecast Calculator page before the Dashboard becomes available.",
    disableLeft: true,
  },
  13: {
    status: "CalculatorButton",
    content: "This button will navigate you to the Forecast Calculator page where you can start configuring your custom inputs. Please click on the button to get started.",
    disableRight: true,
  },
  14: {
    status: "CalculatorIntro",
    content: "This is the Forecast Calculator Page. Here is a description of what this page is used for.",
    disableLeft: true,
  },
  15: {
    status: "CalculatorForecast",
    content: "This section of the page shows the same blue Simulated line from the Report Card page compared to a green Custom line. The green Custom line will only appear after you have saved a configuration for the inputs below.",
  },
  16: {
    status: "CalculatorInputs",
    content: "These inputs control how the simulator will behave when forecasting the Custom line. Each slider will have a different effect on how the simulator behaves. We suggest clicking on each input to learn more about them.",
  },
  17: {
    status: "CalculatorCalculate",
    content: "This is the Calculate button. If you click this, a new green line will be plotted based on the current configuration of inputs. This won't save your configuration permanently - it will only show you what the forecast looks like above.",
  },
  18: {
    status: "CalculatorSave",
    content: "This is the Save Configuration button. Unlike the Calculate button, it will permanently save your current configuration which will be used in the Dashboard page.",
  },
  19: {
    status: "CalculatorDetails",
    content: "For more details pertaining to the forecasts shown above you can look at our detail charts in this section below.",
  },
  20: {
    status: "CalculatorHelp",
    content: "Just as in the Report Card page, you can click this help button to see how the forecast is rooted in the original cashflow table from the currently selected Reserve Fund Study.",
    disableRight: true,
  },
  // Second half of tour: steps 21-27 (after configuration saved)
  // (after a calculator configuration has first been saved and the user is on the Landing page, start the second half of the tour)
  21: {
    status: "DashboardButton2",
    content: "Now that you have saved a configuration of inputs in the Calculator page you can access the Dashboard page.",
    disableLeft: true,
    disableRight: true
  },
  22: {
    status: "DashboardIntro",
    content: "This is the Dashboard Page. Here is a description of what this page is used for.",
    disableLeft: true,
  },
  23: {
    status: "DashboardCashBalanceAlert",
    content: "This alert is notifying you that you will need to provide the current cash balance of your reserve fund to obtain acess to the Next Actions component below. Please do so before continuing.",
  },
  24: {
    status: "DashboardNextActions",
    content: "This is the most valuable part of this page. The Next Actions, which present the actual steps you must take in order to replicate the Custom green line from the forecast in the Calculator Page. Please make sure that you keep your reserve fund cash balance up to date so that these Next Actions stay accurate.",
  },
  25: {
    status: "DashboardMaturity",
    content: "This is where you will see the Maturity Chart. If you have uploaded investments data, this chart will show you how much money will be maturing from your current investments in the next few years. Ideally these blue bars are equal height so that everything is spread out nicely.",
  },
  26: {
    status: "DashboardInvestmentsTab",
    content: "Again, if you have uploaded investments data, you can click on this Investments Tab to see your investments!.",
  },
  27: {
    status: "DashboardHistoryTab",
    content: "Finally, you can click on this History tab to see a visualization of your reserve fund history. Our system will do it's best to estimate the historical value of your fund using the uploaded cashflow tables but will continue to become more accurate as you provide cash balance updates.",
    disableRight: true,
  }
}

const ProductTour = (({pathname}) => {
  const [showProductTour, setShowProductTour] = useState(false)
  const [tooltipContent, setTooltipContent] = useState("")
  const [currentStep, setCurrentStep] = useState(1)
  
  const auth = useContext(authContext)
  const highlightRef = useRef(null)
  const tooltipRef = useRef(null)
  const skipNextTrigger = useRef(false)

  const [scrollPos, setScrollPos] = useState(0)
  const [highlightStyleTop, setHighlightStyleTop] = useState({})
  const [highlightStyleLeft, setHighlightStyleLeft] = useState({})
  const [highlightStyleRight, setHighlightStyleRight] = useState({})
  const [highlightStyleBottom, setHighlightStyleBottom] = useState({})

  const [toolTipStyle, setToolTipStyle] = useState({})

  // TODO update tooltip/highlight position on scroll event
  // let currentScrollHandler = null // This will hold the current scroll handler function

  function getHighlightPosition(selector) {
    const element = document.querySelector(selector)
    if (!element) return null
    var position = element.getBoundingClientRect()
    
    // It works but at what cost
    if(selector == "#ManageDataTour") {
      position.height += 85
      let width = 55
      position.width += width
      position.x -= width
    }
    return position
  }

  function createTooltip(position) {
    if (auth.tourStatus == "Hidden") return
    const tooltip = tooltipRef.current
    
    // update content of tooltip now so that the current tooltip.offsetHeight is available below
    setTooltipContent(STEPS[currentStep].content)
    
    const tooltipWidth = 350 // equal to .tour-tooltip width in css
    const tooltipHeight = tooltip.clientHeight
    const margin = 20

    // if no space on right side of highlighted element
    if (position.right + tooltipWidth > window.innerWidth)
    {
      // if also no space on left side of highlighted element
      if (position.left - tooltipWidth - margin < 5)
      {
        // if no space above
        if (position.top-200 < 5)
        {
          //console.log("Position Below")
          // position below
          // tooltip.style.top = scrollPos + window.innerHeight - 5//`${position.bottom+margin}px`
          // tooltip.style.left = `${window.innerWidth - tooltipWidth - margin - 5}px`

          setToolTipStyle({
            top: Math.max(position.bottom + margin, margin),
            left: `${window.innerWidth - tooltipWidth - margin - 5}px`
          })
        }
        else
        {
          //console.log("Position Above")
          // position above
          // tooltip.style.top = scrollPos + margin//`${position.top - tooltip.offsetHeight - margin}px`
          // tooltip.style.left = `${window.innerWidth - tooltipWidth * 1.5 - margin - 5}px`
          setToolTipStyle({
            top: Math.min(Math.max(position.top - tooltip.offsetHeight - margin, margin), window.innerHeight - margin - tooltipHeight), //`${position.top}px`
            left: `${window.innerWidth - tooltipWidth * 1.5 - margin - 5}px`
          })
        }
      }
      else
      {
        //console.log("Position Left")
        // position to left
        // tooltip.style.top = 500//`${position.top}px`
        // tooltip.style.left = `${position.left-tooltipWidth-margin}px`
        setToolTipStyle({
          top: Math.min(Math.max(position.top, margin), window.innerHeight - margin - tooltipHeight), //`${position.top}px`
          left: `${position.left-tooltipWidth-margin}px`
        })
        //console.log(Math.max(window.outerHeight + position.top, margin))
      }
    }
    else
    {
      //console.log("Position Right")
      // position to right
      // tooltip.style.top = scrollPos + 500 //`${position.top}px`
      // tooltip.style.left = `${position.left+position.width+margin}px`
      setToolTipStyle({
        top: Math.min(Math.max(position.top, margin), window.innerHeight - margin - tooltipHeight), //`${position.top}px`
        left: `${position.left+position.width+margin}px`
      })
    }
  }

  function handleBack() {
    if (STEPS[currentStep].disableLeft) return
    // Remove the previous scroll event listener if it exists
    // if (currentScrollHandler) window.removeEventListener('scroll', currentScrollHandler)

    auth.updateTourStatus(STEPS[currentStep-1].status)
    setCurrentStep(currentStep-1)
  }

  function handleNext() {
    if (STEPS[currentStep].disableRight) return
    // Remove the previous scroll event listener if it exists
    // if (currentScrollHandler) window.removeEventListener('scroll', currentScrollHandler)
    auth.updateTourStatus(STEPS[currentStep+1].status)
    setCurrentStep(currentStep+1)
  }

  function handleCloseTour() {
    // end the first half of tour but save the current step as #21 for when the user has saved a calculator configuration (i.e. is ready for the second half of tour)
    if (currentStep == 20)
    {
      setShowProductTour(false)
      setCurrentStep(21)
      auth.updateTourStatus("DashboardButton2")
    }
    // normal behaviour
    else
    {
      setShowProductTour(false)
      auth.updateTourStatus("Ignored")
    }
  }

  // debug function
  function handleCustom(custom) {
    auth.updateTourStatus(custom)
  }

  function updateHighlightPos(position) {
    //console.log(position)

    const margin = 20

    var elementDims = {
      top: position.top - margin/2 + window.scrollY,
      left: position.left - margin/2,
      width: position.width + margin,
      height: position.height + margin
    }

    if (elementDims.top < 0) {
      elementDims.height = Math.max(elementDims.height - elementDims.top, 0)
      elementDims.top = 0
    }

    // Top
    setHighlightStyleTop({
      height: elementDims.top 
    })

    // Left
    setHighlightStyleLeft({
      top: elementDims.top,
      height: elementDims.height,
      width: elementDims.left,
    })

    // Right
    setHighlightStyleRight({
      top: elementDims.top,
      height: elementDims.height,
      left: elementDims.left + elementDims.width
    })

    // Bottom
    setHighlightStyleBottom({
      top: elementDims.top + elementDims.height,
      height: document.documentElement.scrollHeight - (elementDims.top + elementDims.height),
      left: 0,
      bottom: 0,
      overflow: "clip" // Lazy fix and not sure if it even works to prevent extra page height
    })
    // document.documentElement.offsetHeight

    //console.log(highlightRef.current.style)

    // Create and position highlight
    // const highlight = highlightRef.current
    // const margin = 10
    // highlight.style.top = `${position.top - margin + window.scrollY}px`
    // highlight.style.left = `${position.left - margin}px`
    // highlight.style.width = `${position.width + margin*2}px`
    // highlight.style.height = `${position.height + margin*2}px`
  }

  // useEffect(() => {
  //   if (pathname != "/app") return

    
  //   let url_query = `${process.env.REACT_APP_FLASK_IP}/data/settings`
  //   let body = { "email": auth.email, "building_short_name": auth.selectedBuilding }

  //   axios.defaults.withCredentials = true
  //   axios.post(url_query, body)
  //     .then(function (resp) {
  //       console.log("fire data")
  //       if (resp.data["default_settings"] == 1) setConfigSaved(false)
  //       else setConfigSaved(true)
  //     })
  // }, [pathname]) //auth.isContextSecured

  // using pathname to update showProductTour
  useEffect(() => {
    if (auth.tourStatus && auth.tourStatus != "Ignored")
    {
      // prevent tour from starting on login and signup pages
      if (pathname.substring(1, 4) != "app") setShowProductTour(false)

      // start tour on landing page
      if (pathname == "/app") {
        // second half of tour logic
        if (auth.tourStatus == "DashboardButton2")
        {
          if (!auth.configSaved)
          {
            setShowProductTour(false)
            return
          }
          else setCurrentStep(21)
        }

        // Only show the product tour on this page if it is suppose to
        if ((currentStep >= 5 && currentStep < 11) || (currentStep >= 14 && currentStep < 20) || (currentStep >= 22)) setShowProductTour(false)
        else setShowProductTour(true)

        if (currentStep == 11) {
          console.log("11")

          setCurrentStep(12)
          auth.updateTourStatus(STEPS[12].status)
        }
        else if (currentStep == 20) {
          if (!auth.configSaved) setShowProductTour(false)
          else setCurrentStep(21)
          
          auth.updateTourStatus(STEPS[21].status)
        }
      }
      else if (pathname == "/app/report-card")
      {
        console.log("5")
        if (currentStep < 13) {
          setShowProductTour(true)
          setCurrentStep(5)
          auth.updateTourStatus(STEPS[5].status)
        }
      }
      else if (pathname == "/app/calculator")
      {
        console.log("13")
        if (currentStep >= 13 && currentStep < 21) {
          setShowProductTour(true)
          setCurrentStep(14)
          auth.updateTourStatus(STEPS[14].status)
        }
      }
      else if (pathname == "/app/dashboard")
      {
        if (currentStep >= 21) {
          setShowProductTour(true)
          setCurrentStep(22)
          auth.updateTourStatus(STEPS[22].status)
        }
      }
      //else if (pathname == "")
    }
  }, [pathname])

  // tracking which step in the tour the user is on in the server so that if the tour is interrupted the users doesn't lose progress when they return to the app
  useEffect(() => {
    console.log("ProductTour auth.tourStatus:", auth.tourStatus)
    if (auth.tourStatus == "Hidden") {
      skipNextTrigger.current = true
      setShowProductTour(false)
      auth.updateTourStatus(STEPS[currentStep].status)
      return
    }
    if (skipNextTrigger.current) {
      skipNextTrigger.current = false
      console.log("Skipped")
      return
    }
    //if (auth.tourStatus && auth.tourStatus != "Ignored") setShowProductTour(true)
    for (let i = 0; i < Object.keys(STEPS).length; i++)
    {
      if (STEPS[i].status == auth.tourStatus)
      {
        if (auth.tourStatus && auth.tourStatus != "Ignored" && !((i >= 5 && i < 11) || (i >= 14 && i < 20) || (i >= 22))) setShowProductTour(true)

        setCurrentStep(i)
        break
      }
    }
  }, [auth.tourStatus, auth.selectedStudyYear])
  
  // update the tour tooltip when the current step changes
  useEffect(() => {
    if (auth.tourStatus == "Ignored") return
    // necessary for second half of tour logic
    if (auth.tourStatus == "DashboardButton2")
    {
      if (pathname == "/app/calculator") 
      {
        setShowProductTour(false)
        return
      }
      else if (pathname == "/app")
      {
        console.log('auth.configSaved', auth.configSaved)
        if (!auth.configSaved)
        {
          setShowProductTour(false)
          return
        }
      }
    }

    if (showProductTour)
    {
      // console.log("fire")
      var tempCurrentStep = currentStep
      setTooltipContent(STEPS[currentStep].content)
      
      const intervalID = setInterval(() => {
        // Get position of given element
        var selector = "#" + STEPS[currentStep].status + "Tour"
        var position = getHighlightPosition(selector)
        
        // if no element found, skip interval
        if (!position) return
        // found element, clear interval
        else clearInterval(intervalID)

        // prevent multiple setInterval's being run at the same time
        if (currentStep != tempCurrentStep) {
          clearInterval(intervalID)
          return
        }

        updateHighlightPos(position)
        createTooltip(position)
      }, 33)

      // clear interval if no element found after 1 second
      // TODO unless waiting for user input for SelectStudyModal - in which case we should wait until the new element is found. Maybe we should make all steps behave this way?
      setTimeout(() => {clearInterval(intervalID)}, 1000)

      // update tooltip on scroll event
      // currentScrollHandler = () => updateTooltip(tempCurrentStep)
      // window.addEventListener('scroll', currentScrollHandler)
    }
  }, [showProductTour, currentStep, auth.selectedStudyYear, auth.tourStatus])

  // Dynamic updating of highlight during scroll updates
  useEffect(() => {
    console.log(STEPS[currentStep].status)
    const onEvent = (e) => {
      if (e?.target?.documentElement?.scrollTop != null) setScrollPos(e.target.documentElement.scrollTop)
      if (auth.tourStatus == "Ignored") return
      if (!showProductTour) return

      var position = getHighlightPosition("#" + STEPS[currentStep].status + "Tour")
      if (!position) return
      updateHighlightPos(position)
      createTooltip(position)
    }

    window.addEventListener("scroll", onEvent)
    window.addEventListener("resize", onEvent)

    return () => {
      window.removeEventListener("scroll", onEvent)
      window.removeEventListener("resize", onEvent)
    }
  }, [scrollPos, currentStep])
  
  return (
    <>
      {/* <button style={{'position': 'relative', 'zIndex': '101'}} onClick={handleCloseTour}>Close Tour</button>
      <button style={{'position': 'relative', 'zIndex': '101'}} onClick={() => handleCustom("Landing")}>Landing</button>
      <button style={{'position': 'relative', 'zIndex': '101'}} onClick={() => handleCustom("DashboardIntro")}>DashboardIntro</button> */}
      {showProductTour &&
      <div className="product-tour-overlay">
        {/* <div className="highlight" ref={highlightRef} /> */}
        <div className="overlay" ref={highlightRef}>
          <div className="top" style={highlightStyleTop} />
          <div className="left" style={highlightStyleLeft} />
          <div className="right" style={highlightStyleRight} />
          <div className="bottom" style={highlightStyleBottom} />
        </div>
        <div className="tour-tooltip" ref={tooltipRef} style={toolTipStyle}>
          <div className="banner">Step {currentStep}</div>
          <div className="body">
            <p>{tooltipContent}</p>
            <div className="space-between">
              <Button style={{'height': '30px', 'padding': '3px 10px'}} onClick={handleCloseTour}>Close</Button>
              <div style={{'gap': '5px'}} className="space-between">
                <button style={{'height': '25px'}} className="arrow-button" id={(currentStep == 1) || (STEPS[currentStep].disableLeft)?"disable":""} onClick={handleBack}><span className="arrow" id="left"></span></button>
                <button style={{'height': '25px'}} className="arrow-button" id={(currentStep == Object.keys(STEPS).length-1)  || (STEPS[currentStep].disableRight)?"disable":""} onClick={handleNext}><span className="arrow" id="right"></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
})

export default ProductTour