import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"

export default function MissingData(props) {
    const navigate = useNavigate()
    const handleRedirect = () => navigate("/app/manage-data", {state: {pageSource: props.pageSource} })
    
    var showUpload = true
    var showAdditionalText = false
    if (props.showUpload == false) showUpload = false
    if (props.showAdditionalText == true) showAdditionalText = true
    
    return (
        <div className="missing-data flex-center">
            <div>
                <h1>{props.text}</h1>
                <div className="d-flex justify-content-center">
                    {showUpload && (
                    <Button onClick={handleRedirect}>
                        Upload
                    </Button>
                    )}
                    { showAdditionalText && (
                        <h5>{props.additionalText}</h5>
                    )}
                </div>
            </div>
        </div>           
    )
}