import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext
  } from 'react'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap'

// auth
import { authContext } from './authContext.js'

export default function SessionTimeout() {
    // context
    const auth = useContext(authContext)

    //------------------------------------------------------------------------------------------------------------------
    // Main Timer Limits - only change these
    //------------------------------------------------------------------------------------------------------------------

    const MINUTES_WARN = 10
    const MINUTES_EXTRA = 1

    //------------------------------------------------------------------------------------------------------------------
    // Component State
    //------------------------------------------------------------------------------------------------------------------

    const [events, setEvents] = useState(['click', 'load', 'scroll'])
    const [second, setSecond] = useState(0)
    const [isOpenModal, setOpenModal] = useState(false)

    //------------------------------------------------------------------------------------------------------------------
    // Component Hooks
    //------------------------------------------------------------------------------------------------------------------

    let timeStamp
    let warningInactiveInterval = useRef()
    let startTimerInterval = useRef()
    
    // when called, after 60 seconds, call warningInactive with last recorded timestamp
    let timeChecker = () => {
        startTimerInterval.current = setTimeout(() => {
            //console.log("timeChecker")
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp')
            //console.log("storedTimeStamp:", storedTimeStamp)
            warningInactive(storedTimeStamp)
        }, MINUTES_WARN*60000)
    }

    // gets called by timeChecker()
    // on a 1 second interval, check the  
    let warningInactive = (timeString) => {
        // clears timeout from timeChecker() which calls this function
        clearTimeout(startTimerInterval.current)

        warningInactiveInterval.current = setInterval(() => {
            const maxTime = MINUTES_WARN + MINUTES_EXTRA

            // when triggered, diff will be equal to 60 seconds
            const diff = moment.duration(moment().diff(moment(timeString)))
            //console.log("diff (seconds):", diff._milliseconds/1000)
            const minPast = diff.minutes()
            const leftSecond = (MINUTES_WARN * 60) - diff.seconds()
            //console.log("leftSecond:", leftSecond)

            // after 1 minute has passed, warn user
            if (minPast === MINUTES_WARN) 
            {
                setSecond(leftSecond)
                setOpenModal(true)
            }
            // after 2 minutes have passed, logout user
            else if (minPast === maxTime) 
            {
                clearInterval(warningInactiveInterval.current)
                setOpenModal(false)
                sessionStorage.removeItem('lastTimeStamp')
                auth.logout()
            }
        }, 1000) // every 1 second
    }

    // gets called on any event, stores current timestamp
    // if user is unauthorized, remove the last recorded timestamp
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current)
        clearInterval(warningInactiveInterval.current)

        if (auth.authed) 
        {
            timeStamp = moment()
            //console.log("store lastTimeStamp -", timeStamp._d)
            sessionStorage.setItem('lastTimeStamp', timeStamp)
        } 
        else 
        {
            clearInterval(warningInactiveInterval.current)
            sessionStorage.removeItem('lastTimeStamp')
        }

        timeChecker()
        setOpenModal(false)
    }, [auth.authed])

    // handle close popup
    function handleCloseModal() {
        setOpenModal(false)
        resetTimer()
    }

    // life cycle hook
    useEffect(() => {
        events.forEach(event => window.addEventListener(event, resetTimer))

        timeChecker()

        return () => {
            clearTimeout(startTimerInterval.current)
        }
        
    }, [resetTimer, events, timeChecker])

    if (!isOpenModal) return null

    return (
        <Modal className="timeout-modal" dialogClassName="modal-width" show={isOpenModal} backdrop="static" size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Timeout Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You have been inactive for {MINUTES_WARN} minute{MINUTES_WARN === 1 ? "" : "s"} and will be logged out after {MINUTES_EXTRA} more minute{MINUTES_EXTRA === 1 ? "" : "s"}.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Stay Logged In</Button>
            </Modal.Footer>
        </Modal>
    )
  }
