import { useState, memo } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { formatAmount } from '../lib/helpers/formattingHelpers.js'
import { onMouseOutSync } from '../lib/helpers/highchartsHelpers.js'

import handPointer from '../svg/hand-pointer.svg'

// ------------------------------------------------------------------------
// Preventing re-rendering child components 
// (React.memo and useCallback)
// ------------------------------------------------------------------------

// In this scenario, we have a parent component ReportCard and a child component OffCanvasCashflow. 
// The parent component holds a state variable (labelPoint), and the child component updates this state. 
// We want to prevent the OffCanvasCashflow from re-rendering every time the parent's state is updated.

// Using React.memo() and useCallback()
 
// By memoizing this child component (only updates this component if the props its passed change) and passing a callback (onSyncCharts)
// that is able to execute state changes to the parent from within the parent (but called from the child component), we are able
// to prevent re-rendering this component

// Note: this child component must be in a seperate file for this functionality to work as expected.

const OffCanvasBudget = memo(({ show, selectedBuilding, onSyncCharts, onClose, dataCategoryActual, dataCategoryApproved}) => {
  const [hoveredColIndex, setHoveredColIndex] = useState(null)
  const [budgetYear, setBudgetYear] = useState(dataCategoryActual[1][0][0])
  
  const categories = ["Service Contracts", "Repairs & Maintenance", "Recreational Facilities", "Shared Facilities", "On-Site Personnel", "Utilities", "Guest Suites", "Administrative Expenses"]
  const budgetYears = dataCategoryActual[1].map(x => x[0])

  for (let i = 0; i < budgetYears.length; i++)
  {
    if (budgetYear === budgetYears[i])
    {
      var yearIndex = i
      break
    }
  }

  var disableLeftArrow=false
  if (yearIndex===0) disableLeftArrow = true
  var disableRightArrow=false
  if (yearIndex+1===budgetYears.length) disableRightArrow = true

  function handleColumnMouseEnter(colIndex) {
    setHoveredColIndex(colIndex)
    var e
    if (colIndex === 0)
    {
      e = {target: {index: yearIndex}}
      onSyncCharts(e, ['actual budget by category', 'actual vs approved'], false)
    }
    else if (colIndex === 1)
    {
      e = {target: {index: yearIndex+1}}
      onSyncCharts(e, ['actual vs approved'], false)
    }
  }
  function handleColumnMouseLeave() {
    setHoveredColIndex(null)
    onMouseOutSync()
  }

  function Column(props) {

    const isActual = (props.name.search("Actual")>0)
    
    var tempData = []
    var colIndex = 1
    if (isActual)
    {
      tempData = dataCategoryActual
      colIndex = 0
    }
    else tempData = dataCategoryApproved

    function Header() {
      if (isActual)
      {
        return (
          <div style={{'display': 'flex', 'flexDirection': 'column'}}>
            <u>Previous</u>
            <u>Year Actual</u>
            <u>{budgetYear + "/" + (budgetYear+1).toString().substring(2,4)}</u>
          </div>
        )
      }
      else 
      {
        return (
          <div style={{'display': 'flex', 'flexDirection': 'column'}}>
            <u>Approved</u>
            <u>Budget</u>
            <u>{(budgetYear + 1) + "/" + (budgetYear+2).toString().substring(2,4)}</u>
          </div>
        )
      }
  
    }

    return (
      <div className={'column' + (hoveredColIndex === colIndex ? ' hovered' : '')} onMouseEnter={() => handleColumnMouseEnter(colIndex)} onMouseLeave={() => handleColumnMouseLeave()}>
        <Header />
        {categories.map((category, i) => <div className='cell'>{formatAmount(tempData[i+1][yearIndex + (isActual?0:1)][1], 0)}</div>)}
      </div>
    )
  }

  function handleClickArrowLeft() {
    if (!disableLeftArrow) setBudgetYear(prev => prev-1)
  }
  function handleClickArrowRight() {
    if (!disableRightArrow) setBudgetYear(prev => prev+1)
  }

  function CellDescription() {

    var col = ""
    var description = ""
    if (hoveredColIndex !== null)
    {
      if (hoveredColIndex === 0) 
      {
        col = "Previous Year Actual"
        description = "The true amount spent for budgetted items, retrieved from previous years receipts."
      }
      else if (hoveredColIndex === 1)
      {
        col = "Approved Budget"
        description = "The amount budgetted and approved by the board for the current fiscal year that a budget is concerned with."
      }
    }

    return (
      <div className='cell-description component-container'>
        {col === "" && 
        <div style={{'textAlign': 'center'}}>
          <b>Hover over the Operating Budget above!</b>
          <img style={{'marginTop': '-2px', 'marginLeft': '4px'}} src={handPointer} alt="Hand Pointer" width="20" height="20" />
        </div>}
        {col && <b>{col}</b>}
        {col && <div style={{'paddingTop': '2%'}}>{description}</div>}
      </div>
    )
  }

  return (
    <Offcanvas className="offcanvas-tutorial" show={show} onHide={onClose} scroll={true} backdrop={false}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Understanding this Page</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div style={{'paddingBottom': '2%'}}>
          We use your condominium's historical operating budgets to create the different charts on this page. 
        </div>
        <div style={{'paddingBottom': '2%'}}>
          This tab is intended to help give you an understanding of how the operating budgets correspond to the charts seen on this page.
        </div>
        <div style={{'paddingBottom': '5%'}}>
          Hover over the Operating Budget below to learn more...
        </div>
        <div className='flex-center' style={{'alignItems': 'center', 'marginBottom': '10px'}}>
          <button className="arrow-button" id={disableLeftArrow?"disable":""} onClick={handleClickArrowLeft}><span className="arrow" id="left"></span></button>
          {budgetYear+1}/{budgetYear+2}
          <button className="arrow-button" id={disableRightArrow?"disable":""} onClick={handleClickArrowRight}><span className="arrow" id="right"></span></button>
        </div>
        <div className='component-container cashflow-table'>
          <div style={{'textAlign': 'center'}}>
            <b style={{'font-size': '12px'}}>OPERATING BUDGET - MAY 1, {budgetYear+1} to APRIL 30, {budgetYear+2}</b><br></br>
            <b style={{'font-size': '12px'}}>Toronto Standard Condominium Corporation No. {selectedBuilding.substring(selectedBuilding.length-4, selectedBuilding.length)}</b>
          </div>
          <br></br>
          <div className='flex flex-center'>
            <div className='column' style={{'width': '40%', 'marginRight': '4px'}}><div className='cell' style={{'textAlign': 'center', 'font-size': '18px', 'padding': '10px', 'backgroundColor': '#d3d3d3 '}}>SUMMARY<br></br></div>{categories.map((category) => <div className='cell'>{category}</div>)}</div>
            <Column name={"Previous Year Actual"} />
            <Column name={"Approved Budget"} />
          </div>
        </div>
        <CellDescription />
      </Offcanvas.Body>
    </Offcanvas>
  )
})

export default OffCanvasBudget
