import React from 'react'
import ReactDOM from 'react-dom/client'

import 'bootstrap'
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from './components/authContext.js';
import { DataProvider } from './components/dataContext.js'
import SessionTimeout from './components/SessionTimeout.js';

// components
import App from './components/App.js';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <div id="index">
    <BrowserRouter>
      <AuthProvider>
        <DataProvider>
          <App />
        </DataProvider>
        <SessionTimeout />
      </AuthProvider>
    </BrowserRouter>
  </div>
);
