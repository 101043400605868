// give breakdown of expenses for RFS years that are projected to have the biggest expenses
// hard code 2-3 expenses per building in the system
// at some point before next stage of business (after we have aquired 50 buildings) this process should be updated
// - data intake process; include more than just the cashflow
// - store data in backend database

const mapExpenseFlags = {
  "TSCC 2155": {
    2047: "Replacement Generator", 
    2049: "Window Wall (1)", 
    2050: "Window Wall (2)"
  },
}

export function getExpenseFlag(building, year) {
    if (building in mapExpenseFlags) return mapExpenseFlags[building][year]
    else return undefined
}

const mapEnergyFlags = {
  "TSCC 2155": {
    1637730000000: "Correct Bill Data", 
    1635048000000: "Correct Bill Data", 
  },
}

export function getEnergyFlag(building, date) {
    if (building in mapEnergyFlags) return mapEnergyFlags[building][date]
    else return undefined
}
