import { useState, createContext, useContext, useEffect} from "react"
import { formatDateDashes, helperDateDifference } from '../lib/helpers/formattingHelpers.js'
import { formatReactableColumns, formatServerData } from '../lib/reactableBuilder.js'
import { cumulativeMaturityChartPlot, dateConversion, maturityChartPlot, helperPlot, helperPlotRange, negativeCash } from '../lib/helpers/highchartsHelpers.js'
import api from '../lib/api.js'

import electricity from '../svg/VCI Logo and Icons-electricity.png'
import steam from '../svg/VCI Logo and Icons-steam.png'
import naturalgas from '../svg/VCI Logo and Icons-naturalgas.png'
import water from '../svg/VCI Logo and Icons-water.png'
import getUtilityIcon from '../svg/utilityIcons/interfaceUtilityIcons.js'

// auth
import { authContext } from './authContext.js'

export const dataContext = createContext()

function useData() {
  const MONTH_RANGE = 6
  const auth = useContext(authContext)

  // Budget page data
  const [dataItem, setDataItem] = useState([])
  const [dataItemNumber, setDataItemNumber] = useState([])
  const [dataFiscal, setDataFiscal] = useState([])
  const [dataYear, setDataYear] = useState([])
  const [dataApproved, setDataApproved] = useState([])
  const [dataActual, setDataActual] = useState([])
  const [dataSurplus, setDataSurplus] = useState([])
  const [dataSeriesSubCategoryActual, setDataSeriesSubCategoryActual] = useState([])
  const [dataCategoryActual, setDataCategoryActual] = useState({})
  const [dataCategoryApproved, setDataCategoryApproved] = useState({})
  const [dataSubCategoryActual, setDataSubCategoryActual] = useState({})
  const [dataSubCategoryApproved, setDataSubCategoryApproved] = useState({})
  const [dataYearlySums, setDataYearlySums] = useState({})
  const [subCategories, setSubCategories] = useState({
    "1": {
      "1A": "Air Conditioning & Heating",
      "1B": "Waste Disposal",
      "1C": "Elevator Maintenance",
      "1D": "Grounds Maintenance",
      "1E": "Access Control",
      "1F": "Superintendent Services",
      "1G": "Window Cleaning",
      "1H": "Power Sweep / Wash",
      "1I": "Housekeeping",
      "1J": "Recreation Management",
      "1K": "Management Fees"
    },
    "2": {
      "2A": "HVAC Repairs",
      "2B": "Plumbing",
      "2C": "Electrical",
      "2D": "General Landscaping",
      "2E": "General Access Control Expenses",
      "2F": "Waste Disposal",
      "2G": "Elevator",
      "2H": "Fire Equipment Maintenance",
      "2I": "Paint, Plaster and Wall paper repairs",
      "2J": "Cleaning and Sanitary Supplies",
      "2K": "Emergency Cleanup",
      "2L": "General Repairs & Supplies",
      "2M": "Carpet Cleaning & Repairs"
    },
    "3": {
      "3A": "Pool & Whirlpool Repairs",
      "3B": "Social Committee Activities",
      "3C": "Other Recreational Facilities",
      "3D": "Cinema Projector Expenses"
    },
    "4": {
      "4A": "Payments from ROA Partners",
      "4B": "ROA Shared Expenses",
      "4C": "Payments to ROA Partners"
    },
    "5": {
      "5A": "Vacation Relief",
      "5B": "Employee Benefits:",
      "5C": "Club Suite 6"
    },
    "6": {
      "6A": "Steam",
      "6B": "Hydro",
      "6C": "Water"
    },
    "7": {
      "7A": "Special Projects for Consideration",
      "7B": "Reserve Fund Expenditures",
      "7C": "Guest Suite Expenses"
    },
    "8": {
      "8A": "Telephone",
      "8B": "Office Expense",
      "8C": "Photocopier Expenses/Computer",
      "8D": "Meeting Costs",
      "8E": "Building Communication Systems",
      "8F": "Condominium Association Fees",
      "8G": "Insurance",
      "8H": "Audit Fees",
      "8I": "Bank Service Charges - TD Bank",
      "8J": "Consulting & Appraisal",
      "8K": "Legal Fees"
    }
  })

  // calculator page data
  const [dataVCRange, setDataVCRange] = useState([]) 
  const [dataVC, setDataVC] = useState([]) 
  const [dataTrueBenchmark, setDataTrueBenchmark] = useState([])
  const [dataTrueBenchmarkRange, setDataTrueBenchmarkRange] = useState([]) 
  const [dataMinYear, setDataMinYear] = useState(null)
  const [dataMaxYear, setDataMaxYear] = useState(null)
  const [dataContribution, setDataContribution] = useState([])
  const [dataExpenditures, setDataExpenditures] = useState([])
  const [dataInterestRate, setDataInterestRate] = useState([])
  const [dataInflationRate, setDataInflationRate] = useState([])
  const [dataContributionRate, setDataContributionRate] = useState([])
  const [dataCompositionCash, setDataCompositionCash] = useState([])
  const [dataCompositionNegCash, setDataCompositionNegCash] = useState([])
  const [dataCompositionGIC, setDataCompositionGIC] = useState([])
  const [dataCompositionMLGIC, setDataCompositionMLGIC] = useState([])
  const [dataCalcInterestRate, setDataCalcInterestRate] = useState([])
  const [dataCalcInflationRate, setDataCalcInflationRate] = useState([])
  const [dataInterestIncome, setDataInterestIncome] = useState([])
  const [tempWindow, setTempWindow] = useState(null)
  const [dataBaseLow, setDataBaseLow] = useState([])
  const [dataSystemHigh, setDataSystemHigh] = useState([])
  const [dataOpening, setDataOpening] = useState([])
  const [dataClosing, setDataClosing] = useState([])
  const [detailCharts, setDetailCharts] = useState(false)
  const [showProcessingData, setShowProcessingData] = useState(false)
  const [cashflowBuffering, setCashflowBuffering] = useState(true)
  const [recalculationBuffering, setRecalculationBuffering] = useState(false)

  //Chat
  const [chatHistories, setChatHistories] = useState([])
  const [numHistories, setNumHistories] = useState(0)
  const [maxChatId, setMaxChatId] = useState(-1)
  const [selectedSections, setSelectedSections] = useState([])
  const [numBuildingDocs, setNumBuildingDocs] = useState(0)

  // Dashboard
  const [pageSource, setPageSource] = useState("next actions")
  const [currentCashBalance, setCurrentCashBalance] = useState(0)
  const [currentCashBalanceDate, setCurrentCashBalanceDate] = useState(null)
  const [cashBalanceExists, setCashBalanceExists] = useState(false)
  const [daysSinceUpdateCash, setDaysSinceUpdateCash] = useState(null)
  const [currentExpenses, setCurrentExpenses] = useState(null)
  const [sumFutureExpenses, setSumFutureExpenses] = useState(0)
  const [dataFutureExpenses, setDataFutureExpenses] = useState([])
  const [dataEvents, setDataEvents] = useState([])
  const [nextActions, setNextActions] = useState([])
  const [numSlides, setNumSlides] = useState({})
  const [investmentTableColumns, setInvestmentTableColumns] = useState([])
  const [investmentTableData, setInvestmentTableData] = useState([])
  const [dataMaturity, setDataMaturity] = useState([])
  const [cumulativeDataMaturity, setCumulativeDataMaturity] = useState([])
  const [dataInvestments, setDataInvestments] = useState(null)
  const [allHistoryData, setAllHistoryData] = useState([])
  const [estimateEnds, setEstimateEnds] = useState(0)
  const [dataHistorical, setDataHistorical] = useState([])
  const [dataHistoricalLastDate, setDataHistoricalLastDate] = useState([])
  const [contributionsAmount, setContributionsAmount] = useState(null)
  const [expendituresAmount, setExpendituresAmount] = useState(null)
  const [updatedCashBalance, setUpdatedCashBalance] = useState(false)
  const [updatedExpenses, setUpdatedExpenses] = useState(false)
  const [dataDashboardExists, setDataDashboardExists] = useState(false)

  // Energy
  const [showMissingEnergyData, setShowMissingEnergyData] = useState(false)
  const [mapUtilityIcons, setMapUtilityIcons] = useState({ 'electricity': electricity, 'steam': steam, 'naturalgas': naturalgas, 'water': water })
  const [availableUtilities, setAvailableUtilities] = useState([])
  const [selectedUtility, setSelectedUtility] = useState(null)
  const [utilityData, setUtilityData] = useState({})
  const [utilitySummaryData, setUtilitySummaryData] = useState(null)

  // Free Report
  const [dataCashflow, setDataCashflow] = useState([])
  const [dataNetFunding, setDataNetFunding] = useState([])
  const [dataClosingBalance, setDataClosingBalance] = useState([])
  const [dataStudyBenchmark, setDataStudyBenchmark] = useState([])
  const [dataReportCard, setDataReportCard] = useState({})
  const [dataGrade, setDataGrade] = useState(["", ""]) // ["A", "Description"]
  const [dataTestBreakdown, setDataTestBreakdown] = useState([])
  const [dataGradeBreakdown, setDataGradeBreakdown] = useState([])

  // Manage Data
  const [utilityFiles, setUtilityFiles] = useState(null)
  const [investmentData, setInvestmentData] = useState(null)
  const [processingCashflow, setProcessingCashflow] = useState(false)
  const [processingUtility, setProcessingUtility] = useState(false)
  const [processingInvestments, setProcessingInvestments] = useState(false)
  const [cashflowData, setCashflowData] = useState({})
  const [dataType, setDataType] = useState("cashflow")

  // Report Card
  const [reportCardBuffering, setReportCardBuffering] = useState(true)
  const [updateCharts, setUpdateCharts] = useState(false)
  const [dataFailures, setDataFailures] = useState({})
  const [dataSubtestBreakdown, setDataSubtestBreakdown] = useState([])

  // Building Data
  const [savedConfig, setSavedConfig] = useState(false)
  const [availableRFStudies, setAvailableRFStudies] = useState([])
  const [savedRiskLevel, setSavedRiskLevel] = useState(0)
  const [savedMinCash, setSavedMinCash] = useState(0)
  const [savedInflationYield, setSavedInflationYield] = useState(0)
  const [savedInflationVol, setSavedInflationVol] = useState(0)
  const [savedGICYield, setSavedGICYield] = useState(0)
  const [savedGICVol, setSavedGICVol] = useState(0)
  const [savedMLGICYield, setSavedMLGICYield] = useState(0)
  const [savedMLGICVol, setSavedMLGICVol] = useState(0)
  const [savedCashIntRate, setSavedCashIntRate] = useState(0)
  const [dataExtremes, setDataExtremes] = useState({})
  const [buildingSettingsReceived, setBuildingSettingsReceived] = useState(false)

  const [showMissingBudgetData, setShowMissingBudgetData] = useState(false)
  const [showMissingRFData, setShowMissingRFData] = useState(false)
  const [showSelectStudyModal, setShowSelectStudyModal] = useState(false)
  const [showMissingDashboardData, setShowMissingDashboardData] = useState(false)


  // Helper functions
  function updateBudgetChartData(dataFromServer, selectedItemNumber) {
    setDataItem(dataFromServer['item'])
    setDataItemNumber(dataFromServer['itemnumber'])
    setDataFiscal(dataFromServer['fiscalperiod'])
    setDataYear(dataFromServer['year'])
    setDataApproved(dataFromServer['approved'])
    setDataActual(dataFromServer['actual'])
    setDataSurplus(dataFromServer['surplusdeficit'])

    const tempDataCategoryApproved = {}
    const tempDataCategoryActual = {}
    const tempDataSubCategoryApproved = {}
    const tempDataSubCategoryActual = {}

    //const trackItemRoot = 0
    // const tempCategories TODO
    //const tempSubCategories = {}
    for (var i = 0; i < dataFromServer["itemnumber"].length; i++)
    {
      if (dataFromServer["itemnumber"][i].length == 1)
      {
        //trackItemRoot = dataFromServer["itemnumber"][i]
        //tempCategories.push(dataFromServer["item"][i]) TODO
        if (tempDataCategoryApproved[dataFromServer["itemnumber"][i]])
        {
          tempDataCategoryApproved[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["approved"][i]])
          if (dataFromServer["year"][i] != 2023) tempDataCategoryActual[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["actual"][i]])
        }
        else 
        {
          tempDataCategoryApproved[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["approved"][i]]]
          if (dataFromServer["year"][i] != 2023) tempDataCategoryActual[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["actual"][i]]]
        }
      }
      else
      {
        // create subCategories TODO
        // if (tempSubCategories[trackItemRoot])
        // {
        //   tempSubCategories[trackItemRoot][dataFromServer["itemnumber"][i]] = dataFromServer["item"][i]
        // }
        // else tempSubCategories[trackItemRoot] = {dataFromServer["itemnumber"][i]: dataFromServer["item"][i]}

        if (tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]])
        {
          tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["approved"][i]])
          if (dataFromServer["year"][i] != 2023) tempDataSubCategoryActual[dataFromServer["itemnumber"][i]].push([dataFromServer["year"][i], dataFromServer["actual"][i]])
        }
        else 
        {
          tempDataSubCategoryApproved[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["approved"][i]]]
          if (dataFromServer["year"][i] != 2023) tempDataSubCategoryActual[dataFromServer["itemnumber"][i]] = [[dataFromServer["year"][i], dataFromServer["actual"][i]]]
        }
      }
    }

    const filteredObj = Object.keys(tempDataSubCategoryActual)
    .filter(key => key.startsWith(selectedItemNumber))
    .reduce((acc, key) => {
        acc[key] = tempDataSubCategoryActual[key]
        return acc
    }, {})

    var transformedData = Object.keys(filteredObj).map(key => {
      if (key.charAt(0) == selectedItemNumber)
      {
        return {
          name: subCategories[selectedItemNumber][key],
          type: 'spline',
          data: filteredObj[key],
          marker: {
            symbol: 'circle',
            enabled: false
          }
        }
      }
    })
    setDataSeriesSubCategoryActual(transformedData)

    setDataCategoryActual(tempDataCategoryActual)
    setDataCategoryApproved(tempDataCategoryApproved)
    setDataSubCategoryActual(tempDataSubCategoryActual)
    setDataSubCategoryApproved(tempDataSubCategoryApproved)
    
    // Yearly Sums
    let yearSums = {}
    // Iterate over each category in the object
    for (let category in tempDataCategoryActual) {
      // Iterate over each entry in the category
      for (let entry in tempDataCategoryActual[category]) {
        let year = tempDataCategoryActual[category][entry][0] // The year
        let value = tempDataCategoryActual[category][entry][1] // The value to add

        // If the year is already in the yearSums object, add to its value; otherwise, initialize it
        if (yearSums[year]) {
          yearSums[year] += value
        } else {
          yearSums[year] = value
        }
      }
    }
    setDataYearlySums(yearSums)
  }

  function updateCalculatorChartData(dataFromServer) {
    setDataMinYear(dataFromServer?.year?.[0] ?? null)
    setDataMaxYear(dataFromServer?.['year']?.[dataFromServer?.['year']?.length-1] ?? null)

    setTempWindow(dataFromServer?.['year']?.[0] ?? null)

    setDataBaseLow(dataFromServer?.['base_low'] ?? [])
    setDataSystemHigh(dataFromServer?.['system_high'] ?? []) // TODO calibrate setDataSystemHigh(increaseByPct(dataFromServer['system_high'], .15))
    setDataInterestIncome(dataFromServer?.['interestincome'] ?? [])
    setDataOpening(dataFromServer?.['openingbalance'] ?? [])
    setDataClosing(dataFromServer?.['closingbalance'] ?? [])

    if (dataFromServer === undefined || dataFromServer === null)
    {
      setDataContribution([])
      setDataExpenditures([])
      setDataCompositionCash([])
      setDataCompositionNegCash([])
      setDataCompositionGIC([])
      setDataCompositionMLGIC([])
      setDataCalcInflationRate([])
      setDataCalcInterestRate([])
      setDataInterestRate([])
      setDataInflationRate([])
      setDataContributionRate([])
      setDataStudyBenchmark([])
      setDataTrueBenchmark([])
      setDataTrueBenchmarkRange([])
      setDataVC([])
      setDataVCRange([])
    }
    else
    {
      setDataContribution(helperPlot(dataFromServer['year'], dataFromServer?.['contribution']))
      setDataExpenditures(helperPlot(dataFromServer['year'], dataFromServer?.['expenditureia']))

      setDataCompositionCash(helperPlot(dataFromServer['year'], negativeCash(dataFromServer['system_cash'], true)))
      setDataCompositionNegCash(helperPlot(dataFromServer['year'], negativeCash(dataFromServer['system_cash'])))
      setDataCompositionGIC(helperPlot(dataFromServer['year'], dataFromServer['system_gic']))
      setDataCompositionMLGIC(helperPlot(dataFromServer['year'], dataFromServer['system_mlgic']))

      setDataCalcInflationRate(helperPlot(dataFromServer['year'], dataFromServer['system_inflationrate']))
      setDataCalcInterestRate(helperPlot(dataFromServer['year'], dataFromServer['system_interestrate']))

      setDataInterestRate(helperPlot(dataFromServer['year'], dataFromServer['interestrate']))
      setDataInflationRate(helperPlot(dataFromServer['year'], dataFromServer['inflationrate']))
      setDataContributionRate(helperPlot(dataFromServer['year'], dataFromServer['contributionrate']))

      // Closing Balance
      setDataStudyBenchmark(helperPlot(dataFromServer['year'], dataFromServer['closingbalance']))

      // Benchmark Projections
      setDataTrueBenchmark(helperPlot(dataFromServer['year'], dataFromServer['base']))
      setDataTrueBenchmarkRange(helperPlotRange(dataFromServer['year'], dataFromServer['base_low'], dataFromServer['base_high']))

      // Vertical City Projections
      setDataVC(helperPlot(dataFromServer['year'], dataFromServer['system']))
      setDataVCRange(helperPlotRange(dataFromServer['year'], dataFromServer['system_low'], dataFromServer['system_high']))
    }
  }

  function formatInvestmentBreakdownData(data) {
    let bankData = {}

    for (let i in data["amount"])
    {
      // purely for demo purposes, this should not be here in production version
      let bank
      bank = data["issuer"][i].toLowerCase()

      // const bank = data["issuer"][i].toLowerCase()

      if (!bankData[bank]) 
      {
        bankData[bank] = { "totalamount": 0, "amount": [], "custodian": [], "issuedate": [], "maturitydate": [], "rate": [], "type": [], "data": [] }
      }

      bankData[bank]["totalamount"] += data["amount"][i]

      // TODO: clean this up based on what data is necessary
      bankData[bank]["data"].push({ "amount": data["amount"][i], "maturitydate": data["maturitydate"][i], "rate": data["rate"][i] })

      // bankData[bank]["amount"].push(data["amount"][i])
      // bankData[bank]["custodian"].push(data["custodian"][i])
      // bankData[bank]["issuedate"].push(data["issuedate"][i])
      // bankData[bank]["maturitydate"].push(data["maturitydate"][i])
      // bankData[bank]["rate"].push(data["rate"][i])
      // bankData[bank]["type"].push(data["type"][i])
    }

    // sorting by total amount
    const sorted = Object.entries(bankData)
      .sort(([, a], [, b]) => b.totalamount - a.totalamount)
      .reduce((r, [bank, data]) => ({ ...r, [bank]: data }), {});
    
    return sorted
  }

  function cashBalanceData(data) {
    // handle no data
    if (data["error"] || Object.keys(data).length === 0) 
    {
      setPageSource('investments')
      return
    }

    setCurrentCashBalance(data["cashbalance"])
    setCurrentCashBalanceDate(formatDateDashes(data["date"]))
    setCashBalanceExists(true)

    var diffDays = helperDateDifference(dateConversion(data["date"]), new Date()) - 1
    setDaysSinceUpdateCash(diffDays)
  }

  function expensesData(data) {
    // handle no data
    if (data["error"] || data.expense.length === 0) 
    {
      setCurrentExpenses(0)
      return
    }

    var sum = 0
    var tempDataFutureExpenses = []
    for (let i = 0; i < data["expense"].length; i++ ) {
      sum += data["expense"][i]
      tempDataFutureExpenses.push({"amount": data["expense"][i], "date": data["date"][i]})
    }
    setSumFutureExpenses(sum)
    setDataFutureExpenses(tempDataFutureExpenses)
    
    var lastIndex = data["date"].length - 1
    setCurrentExpenses(data["expense"][lastIndex])
  }

  function nextActionsData(data) {
    // handle no data
    if (data["error"])
    {
      setPageSource('investments')
      return
    }
    if (Object.keys(data).length === 0) return

    setDataEvents(data["all_actions"])

    let nextActionsTemp = []
    for (let i in data["all_actions"]) 
    {
      if (data["all_actions"][i].action) nextActionsTemp.push(data["all_actions"][i])
    }

    setNextActions(nextActionsTemp)
    
    setNumSlides(Math.ceil(nextActionsTemp.length/5))
  }

  function investmentsData(data) {
    // handle no data
    if (data["error"] || data == undefined) return
    if (Object.keys(data).length === 0 || data["amount"].length === 0) return

    let dataLength = data["amount"].length

    let yearsLeft = []
    for (let i = 0; i < dataLength; i++)
    {
      const yearValue = Number(String(data["maturitydate"][i]).slice(0, 4))

      // only give years left if current date is before maturity date
      if (new Date() < dateConversion(data["maturitydate"][i]))
      {
        yearsLeft[i] = yearValue - (new Date().getFullYear())
      }
      else
      {
        yearsLeft[i] = null
      }

      // TODO: temporary fix to accomodate for inconsistencies in naming of scotiabank
      if (data["issuer"][i].toLowerCase() === "bank of nova scotia")
      {
        data["issuer"][i] = "SCOTIA"
      }
    }

    data["yearsleft"] = yearsLeft

    // setting data for detailed investment table view
    const mappingHeaders = { "issuer": "Issuer", "amount": "Amount", "rate": "Rate", "issuedate": "Issue Date", "maturitydate": "Maturity Date", "yearsleft": "Year Diff", "type": "Type", "description": "Description" }
    const formatDateHeaders = ["Maturity Date", "Issue Date"]
    const formatAmountHeaders = ["Amount"]
    const formatRateHeaders = ["Rate"]

    // format data for different components
    const formattedInvestmentTableData = formatServerData(data, mappingHeaders, formatDateHeaders, formatAmountHeaders, formatRateHeaders)
    const investmentTableColumns = formatReactableColumns(formattedInvestmentTableData[0], formatDateHeaders, formatAmountHeaders)

    // reactable data
    setInvestmentTableColumns(investmentTableColumns)
    setInvestmentTableData(formattedInvestmentTableData)

    // setting data for Maturity Chart Component
    setDataMaturity(maturityChartPlot(data['maturitydate'], data['amount'], true, true))
    setCumulativeDataMaturity(cumulativeMaturityChartPlot(data['maturitydate'], data['amount'], true, true))

    // data for ivestments
    setDataInvestments(formatInvestmentBreakdownData(data))
  }

  function historyData(data) {
    // handle no data
    if (data["error"] || Object.keys(data).length === 0) 
    {
      setShowMissingDashboardData(true)
      return
    }

    setAllHistoryData(data)
    const portfolioData = []
    let estimateSet = false
    for (let i in data["portfolio"]) 
    {
      portfolioData.push({ y: data["portfolio"][i], option: data["estimate"][i] })
      if (!data["estimate"][i] && !estimateSet)
      {
        setEstimateEnds(Date.parse(formatDateDashes(data["date"][i])))
        estimateSet = true
      }
    }
    setDataHistorical(portfolioData)
    setDataHistoricalLastDate(data["date"][data["date"].length-1])
  }

  // TODO fix
  function processCashflowData(data) {
    // handle no data
    if (data["error"] || Object.keys(data).length === 0) 
    {
      setShowMissingDashboardData(true)
      return
    }

    const currentYear = new Date().getFullYear()
    for (let i = 0; i < data['year'].length; i++) {
      if (currentYear == data['year'][i])
      {
        setContributionsAmount(data['contribution'][i])
        setExpendituresAmount(data['expenditureia'][i])
        break
      }
    }
    setShowMissingDashboardData(false)
    setDataDashboardExists(true)
  }

  function updateChartFreeReportData(dataFromServer) {
    setDataMinYear(dataFromServer['year'][0])
    setDataMaxYear(dataFromServer['year'][dataFromServer['year'].length-1])

    setDataCashflow(dataFromServer)

    // setDataContribution(helperPlot(dataFromServer['year'], dataFromServer['contribution']))
    // setDataExpenditures(helperPlot(dataFromServer['year'], dataFromServer['expenditureia']))

    var netFunding = []
    dataFromServer['contribution'].map((contribution, index) => {
      netFunding.push(contribution - dataFromServer['expenditureia'][index])
    })
    setDataNetFunding(helperPlot(dataFromServer['year'], netFunding))
    setDataClosingBalance(helperPlot(dataFromServer['year'], dataFromServer['closingbalance']))

    // Closing Balance
    setDataStudyBenchmark(helperPlot(dataFromServer['year'], dataFromServer['closingbalance']))

    // Benchmark Projections
    setDataTrueBenchmark(helperPlot(dataFromServer['year'], dataFromServer['base']))
    setDataTrueBenchmarkRange(helperPlotRange(dataFromServer['year'], dataFromServer['base_low'], dataFromServer['base_high']))

    // Vertical City Projections
    setDataVC(helperPlot(dataFromServer['year'], dataFromServer['scenario1']))
    setDataVCRange(helperPlotRange(dataFromServer['year'], dataFromServer['scenario1_low'], dataFromServer['scenario1_high']))
  }

  // determines when actual energy consumption differs from the predicted consumption range
  function helperOutOfRange(predictedRange, actualConsumption) {
    let outputOutOfRange = []
    let tempOutOfRange = []
    let startGreaterRange = false
    let startLessRange = false

    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - MONTH_RANGE)
    for (let i in predictedRange)
    {
      // only cares about data if within given amount of months (6)
      if (actualConsumption[i][0] > currentDate)
      {
        // checks if consumption is less than the predicted lower bound
        if (actualConsumption[i][1] < predictedRange[i][1]) 
        {
          // handles rare case of if consumption is greater than predicted, then immediately less than predicted
          if (startGreaterRange)
          {
            outputOutOfRange.push({ type: "+", range: tempOutOfRange })
            tempOutOfRange = []
            startGreaterRange = false
          }

          // store date and amount under lower bound in temporary array
          tempOutOfRange.push({ date: actualConsumption[i][0], difference: predictedRange[i][1] - actualConsumption[i][1], amount: predictedRange[i][1] })
          startLessRange = true
          continue
        }
        // checks if consumption is higher than the predicted upper bound
        else if (actualConsumption[i][1] > predictedRange[i][2])
        {
          // handles rare case of if consumption is less than predicted, then immediately greater than predicted
          if (startLessRange)
          {
            outputOutOfRange.push({ type: "-", range: tempOutOfRange })
            tempOutOfRange = []
            startLessRange = false
          }

          tempOutOfRange.push({ date: actualConsumption[i][0], difference: actualConsumption[i][1] - predictedRange[i][2], amount: predictedRange[i][2]})
          startGreaterRange = true
          continue
        }

        // if consumption is within range, add created array of values into outputted array
        if (startGreaterRange)
        {
          outputOutOfRange.push({ type: "+", range: tempOutOfRange })
          startGreaterRange = false
          tempOutOfRange = []
        }
        else if (startLessRange)
        {
          outputOutOfRange.push({ type: "-", range: tempOutOfRange })
          startLessRange = false
          tempOutOfRange = []
        }
      }
    }

    // if consumption ends out of range, add temp array to final array
    if (startGreaterRange)
    {
      outputOutOfRange.push({ type: "+", range: tempOutOfRange })
    }
    else if (startLessRange)
    {
      outputOutOfRange.push({ type: "-", range: tempOutOfRange })
    }

    return outputOutOfRange
  }

  function fetchCashflowData(selectedYear) {
    // fetch cashflow performance - requires data from previous fetch
    const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": selectedYear }
    api.post('/data/cashflow1', body)
    .then(function (resp) {
      setCashflowBuffering(false)
      if (!resp?.data || resp.data["error"])
      {
        setShowProcessingData(true)
        return
      }
      setCashflowData(resp.data)
    })
  }

  function fetchAllUtilityData() {
    let tempData = {}
    let tempSummaryData = {}
    function fetchUtility(index)
    {
      if (index < 0)
        {
          setUtilityData(tempData)
          setUtilitySummaryData(tempSummaryData)
        }
        else
        {
          const utilityType = availableUtilities[index]
          const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "utility_type": utilityType.replace(' ', '') }
          api.post('/data/utility', body)
          .then(function (resp) {
            if (!resp?.data) return
            const serverData = resp.data
            const utilitySummaryData = helperOutOfRange(helperPlotRange(serverData['startdate'], serverData['projconsumptionlow'], serverData['projconsumptionhigh'], true),
            helperPlot(serverData['startdate'], serverData['consumption'], true))
    
            tempData[utilityType] = serverData
            tempSummaryData[utilityType] = utilitySummaryData
            fetchUtility(index - 1)
          }) 
        }
    }
    fetchUtility(availableUtilities.length - 1)
    
  }

  function helperGradeBreakdown(gradeBreakdown) {
    const outputBreakdown = []
    gradeBreakdown.forEach(note => {
      const tempBreakdown = note.split(":")
      if (tempBreakdown[0] == "Max Cash Holdback Test") tempBreakdown.push("A Reserve Fund Study assumes that all cash is invested and earns interest yearly, but in reality, condo boards often hold some cash for emergencies. This results in the actual benchmark being lower than the study's projected benchmark, as less money is invested and earning interest than assumed.")
      else if (tempBreakdown[0] == "Inflation Adjustment Test") tempBreakdown.push("To obtain a more realistic view of how interest and inflation rates change, we model the probabilities using historical data and simulate the forecasts thousands of times using a technique called the Monte Carlo Method.")
      else if (tempBreakdown[0] == "Expense Variance (In Year) Test") tempBreakdown.push("The simulation will vary the scheduled expenses from reserve fund study from 5% to 25% across entire simulation.")
      else if (tempBreakdown[0] == "Unexpected One Time Expense Test") tempBreakdown.push("The simulation will insert a single random $100k - $500k expense dropped into a random year in the simulation.")
        
      outputBreakdown.push(tempBreakdown)
    })
    return outputBreakdown
  }

  useEffect(() => {
    if (Object.keys(cashflowData).length !== 0) 
    {
      updateCalculatorChartData(cashflowData)
      processCashflowData(cashflowData)
    }
  }, [cashflowData])

  useEffect(() => {
    updateCalculatorChartData(null)
  }, [auth.selectedBuilding])

  return {
    // Budget
    dataItem,
    dataItemNumber,
    dataFiscal,
    dataYear,
    dataApproved,
    dataActual,
    dataSurplus,
    dataSeriesSubCategoryActual,
    setDataSeriesSubCategoryActual,
    dataCategoryActual,
    dataCategoryApproved,
    dataSubCategoryActual,
    dataSubCategoryApproved,
    dataYearlySums,
    showMissingBudgetData,
    subCategories,

    // Calculator
    dataVCRange,
    setDataVCRange,
    dataVC,
    setDataVC,
    dataTrueBenchmark,
    dataTrueBenchmarkRange,
    dataMinYear,
    dataMaxYear,
    dataContribution,
    dataExpenditures,
    dataInterestRate,
    dataInflationRate,
    dataContributionRate,
    dataCompositionCash,
    dataCompositionNegCash,
    dataCompositionGIC,
    dataCompositionMLGIC,
    dataCalcInterestRate,
    dataCalcInflationRate,
    dataInterestIncome,
    tempWindow,
    dataBaseLow,
    dataSystemHigh,
    setDataSystemHigh,
    dataOpening,
    dataClosing,
    detailCharts,
    setDetailCharts,
    showProcessingData,
    cashflowBuffering,
    recalculationBuffering,

    // Chat
    chatHistories,
    setChatHistories,
    numHistories,
    maxChatId,
    selectedSections,
    numBuildingDocs,

    // Dashboard
    pageSource,
    currentCashBalance,
    currentCashBalanceDate,
    cashBalanceExists,
    daysSinceUpdateCash,
    currentExpenses,
    sumFutureExpenses,
    dataFutureExpenses,
    dataEvents,
    nextActions,
    numSlides,
    setNumSlides,
    investmentTableColumns,
    investmentTableData,
    dataMaturity,
    cumulativeDataMaturity,
    dataInvestments,
    allHistoryData,
    estimateEnds,
    dataHistorical,
    dataHistoricalLastDate,
    contributionsAmount,
    expendituresAmount,
    updatedCashBalance,
    updatedExpenses,
    showMissingDashboardData,
    dataDashboardExists,

    // Energy
    showMissingEnergyData,
    mapUtilityIcons,
    availableUtilities,
    selectedUtility,
    setSelectedUtility,
    utilityData,
    utilitySummaryData,

    // Free Report
    dataCashflow,
    dataNetFunding,
    dataClosingBalance,
    dataStudyBenchmark,
    dataReportCard,
    dataGrade,
    setDataGrade,
    dataTestBreakdown,
    setDataTestBreakdown,
    dataGradeBreakdown,

    // Manage Data
    utilityFiles,
    dataType, 
    setDataType,
    investmentData, 
    setInvestmentData,
    processingCashflow,
    processingUtility,
    processingInvestments,
    cashflowData,

    // Report Card
    reportCardBuffering,
    updateCharts,
    setUpdateCharts,
    dataFailures,
    setDataFailures,
    dataSubtestBreakdown,
    setDataSubtestBreakdown,

    // Building Data
    savedConfig,
    availableRFStudies,
    setAvailableRFStudies,
    savedRiskLevel,
    savedMinCash,
    savedInflationYield,
    savedInflationVol,
    savedGICYield,
    savedGICVol,
    savedMLGICYield,
    savedMLGICVol,
    savedCashIntRate,
    showMissingRFData,
    setShowMissingRFData,
    showSelectStudyModal,
    setShowSelectStudyModal,
    dataExtremes,
    setDataExtremes,
    buildingSettingsReceived,

    // Fetch Functions
    fetchBudgetData(selectedItemNumber) {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/budget', body)
      .then(function (resp) {
        // handle no RF studies - show MissingData Component
        if (!resp?.data || Object.keys(resp.data).length === 0 || resp.data["error"])
        {
          setShowMissingBudgetData(true)
          return
        }

        updateBudgetChartData(resp.data, selectedItemNumber)
      })
    },
    fetchBuildingSettings() {
      // fetch building settings - requires data from previous fetch
      setBuildingSettingsReceived(false)
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/settings', body)
      .then(function (resp) {
        if (!resp?.data)
        {
          setSavedConfig(false)
          return
        }
        if (resp.data["default_settings"] !== 1)
        {
          setSavedConfig(true)
        }
        else
        {
          setSavedConfig(false)
          setDetailCharts(true)
        }
        setSavedRiskLevel(resp.data["max_pct_mlgic"] * 10)
        setSavedMinCash(resp.data["min_cash_holdback"])
        setSavedMLGICYield(resp.data['mlgic_yield'])
        setSavedMLGICVol(resp.data['mlgic_vol'])
        setSavedGICYield(resp.data['int_rate_yield'])
        setSavedGICVol(resp.data['int_rate_vol'])
        setSavedInflationYield(resp.data['inf_rate_yield'])
        setSavedInflationVol(resp.data['inf_rate_vol'])
        setSavedCashIntRate(resp.data['cash_int_rate'])

        setBuildingSettingsReceived(true)
      })
    },
    fetchRFStudies() {
      setShowMissingRFData(false)
      setShowSelectStudyModal(false)
      setProcessingCashflow(false)
      setAvailableRFStudies([])

      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/rfstudies', body)
      .then(function (resp) {
        // handle no RF studies - show MissingData Component
        if (!resp?.data || Object.keys(resp.data).length === 0 || resp.data["error"])
        {
          setShowMissingRFData(true)
          setShowSelectStudyModal(false)
          return
        }
        setProcessingCashflow(false)
        setAvailableRFStudies(resp.data)
        if (!auth.selectedStudyYear && resp.data.length != 1) setShowSelectStudyModal(true)
      })
    },
    fetchCashflowHistory(body) {
      setRecalculationBuffering(true)
      updateCalculatorChartData(null)
      api.post('/data/cashflow1history', body)
      .then(function (resp) {
        if (!resp?.data) return
        setRecalculationBuffering(false)
        updateCalculatorChartData(resp.data)
      })

      setSavedConfig(true)
      setDetailCharts(false)
    },
    fetchCashflowCustom(body) {
      setRecalculationBuffering(true)
      updateCalculatorChartData(null)
      api.post('/data/cashflow1custom', body)
      .then(function (resp) {
        if (!resp?.data) return
        setRecalculationBuffering(false)
        updateCalculatorChartData(resp.data)
      })

      setSavedConfig(true)
      setDetailCharts(false)
    },
    fetchChatHistory() {
      // get the chat history
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/chathistory', body)
      .then(function (resp) {
        if (!resp?.data || resp.data["error"])
        {
          setNumHistories(0)
          return
        }

        else if (resp.data.num_chat_histories === 0)
        {
          setNumHistories(0)
          return
        }
        setChatHistories(resp.data.history) 
        setNumHistories(resp.data.num_chat_histories)
        if (parseInt(resp.data.history[resp.data.num_chat_histories-1]))
        {
          setMaxChatId(parseInt(resp.data.history[resp.data.num_chat_histories-1]))
        }
      })
    },
    fetchMessageContext(messageId) {
      if (messageId === 0) messageId = 1
      
      setSelectedSections([])
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "message_id": messageId, "chat_history_id": auth.chatId }
      api.post('/data/messagecontext', body)
      .then(function (resp) {
        if (!resp?.data || resp.data["error"])
        {
          return
        }
        setSelectedSections(resp.data.message_context)
      })  
    },
    fetchNumBuildingDocs() {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/buildingdocs', body)
      .then(function (resp) {
        if (!resp?.data || resp.data["error"])
        {
          setNumBuildingDocs(0)
          return
        }
        setNumBuildingDocs(resp.data.num_building_documents)
      })
    },
    fetchDashboardData() {
      setDataDashboardExists(false)
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/cashbalance', body)
      .then(function (resp) {
        // TODO generalized error handling
        if (resp?.data) cashBalanceData(resp.data)
        api.post('/data/expenses', body)
        .then(function (resp) {
          if (resp?.data) expensesData(resp.data)
          api.post('/data/nextactions', body)
          .then(function (resp) {
            if (resp?.data) nextActionsData(resp.data)
            api.post('/data/investments', body)
            .then(function (resp) {
              if (resp?.data) investmentsData(resp.data)
              api.post('/data/history', body)
              .then(function (resp) {
                if (resp?.data)
                {
                  historyData(resp.data)
                  fetchCashflowData(-1)
                }
              })
            })
          })
        })
      })
    },
    fetchUtilities() {
      setUtilityFiles(null)
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/utilities', body)
      .then(function (resp) {

        // handle no data
        if (!resp?.data || Object.keys(resp.data).length === 0 || resp.data["error"])
        {
          setShowMissingEnergyData(true)
          return
        }

        let tempUtilities = []
        let tempMapIcons = {}
        for (let i in resp.data)
        {
          tempUtilities.push(resp.data[i]["type"])
          tempMapIcons[resp.data[i]["type"]] = getUtilityIcon(resp.data[i]["company"],resp.data[i]["type"])
        }
        setUtilityFiles(resp.data)
        setMapUtilityIcons(tempMapIcons)
        setAvailableUtilities(tempUtilities)
        setSelectedUtility(tempUtilities[0])
        setShowMissingEnergyData(false)
        setProcessingUtility(false)
      })
    },
    fetchUtility(utilityType) {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "utility_type": utilityType.replace(' ', '') }
      api.post('/data/utility', body)
      .then(function (resp) {
        if (!resp?.data) return
        setUtilityData(resp.data)
      }) 
    },
    fetchAllUtilityData,
    fetchFixedScenarios(year) {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year }
      api.post('/data/cashflow5', body)
      .then(function (resp) {
        if (!resp?.data || resp.data["error"])
        {
          setShowMissingRFData(true)
          return
        }

        updateChartFreeReportData(resp.data)
      })
    },
    fetchReportCard(year) {
      setReportCardBuffering(true)
      setDataReportCard({})
      setDataGrade(["", ""])
      setDataSubtestBreakdown([])
      setDataGradeBreakdown([])
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year }
      api.post('/data/reportcard', body)
      .then(function (resp) {
        setReportCardBuffering(false)
        if (!resp?.data || Object.keys(resp.data["base"]["grade"]).length == 0 || resp.data["error"])
        {
          setShowProcessingData(true)
          setShowMissingRFData(true)
          return
        }
        
        // set default report card data from server
        setDataReportCard(resp.data)
        let strGrade = resp.data["base"]["grade"][0].split(":") // 'A+: 1000/1000 (0%) test simulations complete'
        setDataGrade(strGrade)
        setDataSubtestBreakdown(resp.data["base"]["subtest"])
        setDataGradeBreakdown(helperGradeBreakdown(resp.data["base"]["test"]["data"]))
        setUpdateCharts(!updateCharts)
      })
    },
    fetchInvestmentData() {
      setInvestmentData(null)
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/data/investments', body)
      .then(function (resp) {
        let dataFromServer = resp?.data
        if (!dataFromServer || dataFromServer["error"]) return
        if (dataFromServer.length === 0 || dataFromServer["amount"].length === 0) return //unsure of this one, do we really want to return if no utility data?

        setInvestmentData(resp.data) //TODO: figure out proper data to be put here\
        setProcessingInvestments(false)
      })
    },
    fetchCashflowData,

    // Upload Functions
    uploadBuildingSettings(body) {
      setRecalculationBuffering(true)
      updateCalculatorChartData(null)
      api.post('/upload/settings', body)
      .then(function (resp) {
        if (!resp?.data) return
        updateCalculatorChartData(resp.data)
        setRecalculationBuffering(false)

        setSavedRiskLevel(body.max_pct_mlgic)
        setSavedMinCash(body.min_cash_holdback)
        setSavedMLGICYield(body.mlgic_yield)
        setSavedMLGICVol(body.mlgic_vol)
        setSavedGICYield(body.int_rate_yield)
        setSavedGICVol(body.int_rate_vol)
        setSavedInflationYield(body.inf_rate_yield)
        setSavedInflationVol(body.inf_rate_vol)
        setSavedCashIntRate(body.cash_int_rate)
      })
      
      auth.setConfigSaved(true)
      setSavedConfig(true)
      setDetailCharts(false)
    },
    uploadCashBalance(cashBalance, date) {
      const formatDate = date.replaceAll("-", "")
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "cashbalance": cashBalance, "date": formatDate }
      api.post('/upload/cashbalance', body)
      .then(function (resp) {
        setCurrentCashBalance(cashBalance)
        setCurrentCashBalanceDate(date)

        var diffDays = helperDateDifference(dateConversion(formatDate), new Date()) - 1
        setDaysSinceUpdateCash(diffDays)

        setCashBalanceExists(true)
        setUpdatedCashBalance(true)
      })
    },
    uploadExpenses(expenses, date) {
      const formatDate = date.replaceAll("-", "")
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "expenses": expenses, "date": formatDate }
      api.post('/upload/expenses', body)
      .then(function (resp) {
          setCurrentExpenses(expenses)
          setUpdatedExpenses(true)
      })
    },
    fileUpload(endpoint, postData) {
      // send POST w file to backend
      api.post(endpoint, postData)
      .then(function (resp) {
        if (dataType === "cashflow") setProcessingCashflow(true)
        else if (dataType === "energy") setProcessingUtility(true)
        else if (dataType === "investments") setProcessingInvestments(true)
      }).catch((error) => {
        console.log(error)
      })
    },

    renameChatHistory(name, id) {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "new_name":name, "chat_history_id": id }
      api.post('/rename/chathistory', body)
    },
    deleteChatHistory(id) {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "chat_history_id": id }
      api.post('/delete/chathistory', body)
    },
    modifyPrimaryBuilding() {
      const body = { "email": auth.email, "building_short_name": auth.selectedBuilding }
      api.post('/modify/primarybuilding', body)
      .then(function (resp) {
        const dataFromServer = resp?.data

        // handle no data
        if (!dataFromServer || Object.keys(dataFromServer).length === 0 || dataFromServer["error"]) return

        auth.setPrimaryBuilding(dataFromServer["building_short_name"])
      })
    }
  }
}

// this is wraped around the app level
export function DataProvider({ children }) {  
    const data = useData() 
    
    // this is providing data to all the chile components
    return <dataContext.Provider value={data}>{children}</dataContext.Provider>
  }